import * as React from "react";
import axios from 'axios';
import { useMediaQuery } from '@mui/material';
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'; // For React Router v6
import styled from "styled-components";
import { Grid } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import { ListItemText } from '@material-ui/core';
import { Select, MenuItem } from '@mui/material';
import {FormControlLabel} from '@mui/material';
import { FaInfoCircle,FaEdit, FaTrash, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PersonIcon from '@mui/icons-material/Person';
import HouseIcon from '@mui/icons-material/House';
import GroupsIcon from '@mui/icons-material/Groups';
import DataTable from 'react-data-table-component';
import { APIURL } from '../Global';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Key } from "@mui/icons-material";

function SurveysTab({ 
  handleNodeSelect, 
  handleTeamMemberClick, 
  selectedGroup, 
  token, 
  selectedGroupId, 
  teamMembers 
}) {
  const [tabIndex, setTabIndex] = useState(0);
  const [formTabIndex, setFormTabIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [contacts, setContacts] = useState([]);


  // const greenColumnStyle = {
  //   backgroundColor: '#B9D4EE',
  // };
  
  const [filters, setFilters] = useState({
    sl_no: '',
    name: '',
    guardian_name: '',
    gender: '',
    polling_booth_number: '',
    house_no: '',
    house_name: '',
    age: '',
    epic_no: '',
    remarks:'',
  });

  
  const handleFilterChange = (e, field) => {
    setFilters({
      ...filters,
      [field]: e.target.value,
    });
  };

  const filteredData = contacts.filter(contact => {
    return (
      (contact.sl_no?.toString() || '').includes(filters.sl_no) &&
      (contact.name?.toLowerCase() || '').includes(filters.name.toLowerCase()) &&
      (contact.guardian_name?.toLowerCase() || '').includes(filters.guardian_name.toLowerCase()) &&
      (contact.gender?.toLowerCase() || '').includes(filters.gender.toLowerCase()) &&
      (contact.polling_booth_number?.toString() || '').includes(filters.polling_booth_number) &&
      (contact.house_no?.toString() || '').includes(filters.house_no) &&
      (contact.house_name?.toLowerCase() || '').includes(filters.house_name.toLowerCase()) &&
      (contact.age?.toString() || '').includes(filters.age) &&
      (contact.epic_no?.toLowerCase() || '').includes(filters.epic_no.toLowerCase()) &&
      (contact.remarks?.toLowerCase() || '').includes(filters.remarks.toLowerCase())
    );
  });
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);


  // Function to fetch individual details from the API
  const fetchIndividualDetails = async (individual_id) => {
    try {
    

      const response = await fetch(`${APIURL}/data_collection/api/individual-details/?individual_id=${individual_id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Token ${token}`
        },
      });

      const data = await response.json();

      if (response.ok && data.status === 'success') {
        setSelectedPerson(data.data); 
        setInfoModalOpen(true); 
      } else {
        alert('Failed to fetch individual details.');
      }
    } catch (error) {
      console.error("Error fetching individual details:", error);
      alert('An error occurred while fetching data.');
    } 
  };

  ;
  
  const handleInfoClick = (row) => {
    const url = `/individual-details/${row.id}`;
    const params = new URLSearchParams({ individualId: row.id, token });
  
   
    window.open(`${url}?${params.toString()}`, '_blank');
  };

  

  const handleHouseInfoClick = (row) => {
    const url = `/house-details/${row.id}`;
    const params = new URLSearchParams({
      household_id: row.id,
      token: token,  
      selectedGroupId: selectedGroupId  
    });
  
   
    window.open(`${url}?${params.toString()}`, '_blank');
  };

  const handleFamilyInfoClick = (row) => {
    const url = `/family-details/${row.id}`;
    const params = new URLSearchParams({ family_id: row.id, token });
  

    window.open(`${url}?${params.toString()}`, '_blank');
  };
  
  // Function to handle closing the modal
  const handleInfoClose = () => {
    setInfoModalOpen(false); // Close the modal
    setSelectedPerson(null); // Reset person data
  };

  const columns = [
    {
      name: (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginBottom: '25px' }}>Sl.No</div>
          <StyledInput
            type="text"
            value={filters.sl_no}
            onChange={(e) => handleFilterChange(e, 'sl_no')}
            width="35px"
          />
        </div>
      ),
      selector: (row) => row.sl_no,
      sortable: true,
      width: '80px', 
    },
    {
      name: (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginBottom: '25px' }}>Name</div>
          <StyledInput
            type="text"
            value={filters.name}
            onChange={(e) => handleFilterChange(e, 'name')}
            width="145px"
          />
        </div>
      ),
      cell: (row) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', padding: '0px 0' }}>
          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginRight: '8px', flex: 1 }}>
            {row.name}
          </div>
          {row.sl_no !== 'No data' && row.name !== 'No data' && row.guardian_name !== 'No data' && row.gender !== 'No data' && row.polling_booth_number !== 'No data' && row.house_no !== 'No data' && row.house_name !== 'No data' && row.age !== 'No data' && row.epic_no !== 'No data' && (
            <div style={{ flexShrink: 0 }}>
              <FaInfoCircle
                style={{ color: '#1d1160', cursor: 'pointer' }}
                size={17}
                onClick={(e) => {
                  e.stopPropagation(); 
                  handleInfoClick(row); 
                }}
              />
            </div>
          )}
        </div>
      ),
      sortable: true,
      selector: (row) => row.name,
      width: '190px',
    },
    {
      name: (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginBottom: '25px' }}>Gender</div>
          <StyledInput
            type="text"
            value={filters.gender}
            onChange={(e) => handleFilterChange(e, 'gender')}
            width="35px"
          />
        </div>
      ),
      selector: (row) => row.gender,
      sortable: true,
      width: '80px',
    },
    {
      name: (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginBottom: '25px' }}>Booth No</div>
          <StyledInput
            type="text"
            value={filters.polling_booth_number}
            onChange={(e) => handleFilterChange(e, 'polling_booth_number')}
            width="65px"
          />
        </div>
      ),
      selector: (row) => row.polling_booth_number,
      sortable: true,
      width: '110px',
    },
    {
      name: (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginBottom: '25px' }}>House No</div>
          <StyledInput
            type="text"
            value={filters.house_no}
            onChange={(e) => handleFilterChange(e, 'house_no')}
            width="55px"
          />
        </div>
      ),
      selector: (row) => row.house_no,
      sortable: true,
      width: '100px',
      // style: greenColumnStyle,
    },
    {
      name: (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginBottom: '25px' }}>House Name</div>
          <StyledInput
            type="text"
            value={filters.house_name}
            onChange={(e) => handleFilterChange(e, 'house_name')}
            width="145px"
          />
        </div>
      ),
      selector: (row) => row.house_name,
      sortable: true,
      width: '190px',
      // style: greenColumnStyle,
    },
    // {
    //   name: 'Verify',
    //   cell: row => (
    //     row.sl_no !== 'No data' && row.name !== 'No data' && row.guardian_name !== 'No data' && row.gender !== 'No data' && row.polling_booth_number !== 'No data' && row.house_no !== 'No data' && row.house_name !== 'No data' && row.age !== 'No data' && row.epic_no !== 'No data' ? (
    //       <div onClick={() => toggleVerification(row)}>
    //         {row.verified ? (
    //           <FaCheckCircle style={{ color: 'green', cursor: 'pointer' }} size={20} />
    //         ) : (
    //           <FaTimesCircle style={{ color: '#787878', cursor: 'pointer' }} size={20} />
    //         )}
    //       </div>
    //     ) : null 
    //   ),
    //   width: '60px',
    //   style: greenColumnStyle,
    // }, 
    {
      name: (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginBottom: '25px' }}>Age</div>
          <StyledInput
            type="text"
            value={filters.age}
            onChange={(e) => handleFilterChange(e, 'age')}
            width="35px"
          />
        </div>
      ),
      selector: (row) => row.age,
      sortable: true,
      width: '80px',
    },
    
    {
      name: (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginBottom: '25px' }}>Epic No</div>
          <StyledInput
            type="text"
            value={filters.epic_no}
            onChange={(e) => handleFilterChange(e, 'epic_no')}
            width="115px"
          />
        </div>
      ),
      selector: (row) => row.epic_no,
      sortable: true,
      width: '160px',
    },
    {
      name: 'Edit',
      cell: (row, index) => (
        row.sl_no !== 'No data' && row.name !== 'No data' && row.guardian_name !== 'No data' && row.gender !== 'No data' && row.polling_booth_number !== 'No data' && row.house_no !== 'No data' && row.house_name !== 'No data' && row.age !== 'No data' && row.epic_no !== 'No data' ? (
          <div onClick={() => editRow(index)}>
            <FaEdit style={{ color: '#1d1160', cursor: 'pointer' }} size={20} />
          </div>
        ) : null 
      ),
      width: '60px',
    }, 
    {
      name: 'Delete',
      cell: (row, index) => (
        row.sl_no !== 'No data' && row.name !== 'No data' && row.guardian_name !== 'No data' && row.gender !== 'No data' && row.polling_booth_number !== 'No data' && row.house_no !== 'No data' && row.house_name !== 'No data' && row.age !== 'No data' && row.epic_no !== 'No data' ? (
          <div onClick={() => deleteRow(row.id)}>
            <FaTrash style={{ color: '#1d1160', cursor: 'pointer' }} size={20} />
          </div>
        ) : null 
      ),
      width: '70px',
    }, 
    {
      name: (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginBottom: '25px' }}>Remarks</div>
          <StyledInput
            type="text"
            value={filters.remarks}
            onChange={(e) => handleFilterChange(e, 'remarks')}
            width="145px"
          />
        </div>
      ),
      selector: (row) => row.remarks,
      sortable: true,
      width: '190px',
    },
];

  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [expanded, setExpanded] = useState('personal');
  const [newContact, setNewContact] = useState({
    group: selectedGroupId,
    polling_booth_number: '',
    polling_booth_name: '',
    sl_no: '',
    house_no: '',
    house_name: '',
    field_of_education:'',
    professsion:'',
    residence_status:'',
    health_insurance:'',
    social_insurance:'',
    welfare_fund:'',
    is_active_on_social_media:'',
    most_active_social_media:'',
    is_student:'',
    name: '',
    guardian_name: '',
    guardian_relation: '',
    dob: '',
    age: '',
    gender: '',
    epic_no: '',
    education: '',
    occupation: '',
    marital_status: '',
    expatriate: 'No',
    vehicles: '',
    differently_abled: 'No',
    chronic_disease: '',
    religion: '',
    caste: '',
    religious_organizations: '',
    social_organizations: '',
    political_affiliation_party: '',
    political_affiliation_status: '',
    pension: false,
    aadhaar_number: '',
    email: '',
    mobile_number: '',
    eligible_to_vote: false,
    remarks:''
  });
  const [currentContact, setCurrentContact] = useState(null);
  const initialContactState = {
    group: selectedGroupId,
    polling_booth_number: '',
    polling_booth_name: '',
    sl_no: '',
    house_no: '',
    house_name: '',
    field_of_education:'',
    professsion:'',
    residence_status:'',
    health_insurance:'',
    social_insurance:'',
    welfare_fund:'',
    is_active_on_social_media:'',
    most_active_social_media:'',
    is_student:'',
    name: '',
    guardian_name: '',
    guardian_relation: '',
    dob: '',
    age: '',
    gender: '',
    epic_no: '',
    education: '',
    occupation: '',
    marital_status: '',
    expatriate: 'No',
    vehicles: '',
    differently_abled: 'No',
    chronic_disease: '',
    religion: '',
    caste: '',
    religious_organizations: '',
    social_organizations: '',
    political_affiliation_party: '',
    political_affiliation_status: '',
    pension: false,
    aadhaar_number: '',
    email: '',
    mobile_number: '',
    eligible_to_vote: false,
    remarks:''
  };

 
    useEffect(() => {
      if (tabIndex === 1) {
        fetchIndividuals(selectedGroupId);
        
      }
    }, [tabIndex , selectedGroupId]); 
    
    useEffect(() => {
      if (tabIndex === 0) {
         fetchHouseData(selectedGroupId);
      }
    }, [tabIndex , selectedGroupId]);
    
    useEffect(() => {
      if (tabIndex === 2) {
        
        fetchFamilyData(selectedGroupId);
      }
    }, [tabIndex , selectedGroupId]);
    


  const openModal = () => {
    setNewContact(initialContactState);
    setFormErrors({}); 
    setModalOpen(true);
  };



  const [verificationModalOpen, setVerificationModalOpen] = useState(false);
  const [verificationData, setVerificationData] = useState({

    house_no: '',
    house_name: ''
  });
  const [formErrors, setFormErrors] = useState({});

  const MARITAL_STATUS_CHOICES = ['Single', 'Married', 'Divorced', 'Widowed'];
  const GENDER_CHOICES = [['M', 'Male'], ['F', 'Female'], ['T', 'Transgender']];
    // const RATION_CHOICES = [['APL', 'APL'], ['BPL', 'BPL'], ['AAY', 'AAY'], ['PHH', 'PHH'], ['AY', 'AY']];
  const RATION_CHOICES = [ ['Yellow', 'Yellow'], ['Pink', 'Pink'],['White', 'White'],['Blue', 'Blue'],];
  const DIFFERENTLY_ABLED_CHOICES = ['Yes', 'No'];
  const EXPATRIATE_STATUS_CHOICES = [ 'No', 'National', 'International'];
  const EDUCATION_CHOICES = ['Below 10th', '10th', '12th', 'Graduation', 'Post-Graduation', 'Above Post-Graduation','Other'];
  const EDUCATION_FIELD_CHOICES = ['Medical', 'Paramedical', 'Engineering', 'Teaching', 'History', 'Physics','Biology','Geography','Other'];
  const PROFESSION_CHOICES = ['Doctor', 'Engineer', 'Nurse', 'Paramedical', 'Teacher', 'Professor','Business','Kooli','Carpenter','Mason','Other'];
const YES_NO_CHOICES = [ true, false];
const BOOLEAN_CHOICES = ['No', 'Yes'];
const VEHICLE_CHOICES = ['None','Two Wheeler', 'Four Wheeler', 'Both', 'Others'];
const WATER_SOURCE_CHOICES = ['Well', 'Borewell', 'Hand pump', 'Municipality water supply', 'River', 'Pond', 'Other'];
const HOUSE_STATUS_CHOICES = ['Own', 'Rented', 'Leased', 'Outlandish land(പുറമ്പോക്ക്)','Other'];
const SPECIAL_TREATMENT_CHOICES = ['Handicaped', 'Bedridden', 'Mental Disorder', 'Terminal illness','Add New'];
const AGRICULTURE_TYPES_CHOICES = ['Rice', 'Vegetable ', 'Coconut','Rubber','Cardamom','Add New'];
const PETS_TYPES_CHOICES = ['None', 'Dog', 'Cow','Goat','Cat', 'Cattle', 'Add New'];
const SUB_CASTE_CHOICES = ['None', 'Add New'];
const FINANCIAL_STATUS_CHOICES = ['Lower Class', 'Middle Class', 'Upper Class'];
const RELIGION_CHOICES = ['Hindu', 'Islam', 'Christian', 'Buddha', 'Other'];
const [otherReligiousOrg, setOtherReligiousOrg] = useState('');
const RELIGION_ORGANIZATIONS_CHOICES = {
  Islam: ['a', 'b'],
  Hindu: ['c', 'd'],
  Christian: ['e', 'f'],
  Buddha: ['g', 'h']
};
// const RELIGION_CHOICES = ['Hindu', 'Islam', 'Christian', 'Budha','Other'];
// const [otherReligiousOrg, setOtherReligiousOrg] = useState('');
// const RELIGIOUS_ORGANIZATIONS = {
//   Hindu: ['A', 'B', 'C', 'D'],
//   Islam: ['E', 'F', 'D', 'G'],
//   Buddha: ['Q', 'W', 'R', 'T'],
//   Christian: ['Z', 'X', 'V', 'M'],
//  }; 
// const RELIGION_ORGANIZATIONS_CHOICES = ['a', 'b', 'c','d','e','f','g','h'];
const CASTE_CHOICES = ['General', 'Muslim','OBC','OEC' ,'SC','ST','Other'];
const POLITICAL_STATUS_CHOICES = ['Active Member', 'Passive Member', 'Sympothiser', 'Other'];
const OCCUPATION_CHOICES = ['Govt', 'Public', 'Private', 'Self','Other'];
const WAY_TO_HOUSE_CHOICES =['Tarred Road','Sand Road' , 'Walkway(നടവഴി)','Others land','Other'];
// const POLLING_BOOTH_NUMBERS = ['1', '2', '3', 'Add New'];
// const POLLING_BOOTH_NAMES = ['Booth A', 'Booth B', 'Booth C', 'Add New'];

const [apiChoices, setApiChoices] = useState({
  specialTreatments: [],
  petsTypes: [],
  agricultureTypes: [],
  waterSources: [],
  houseStatuses: [],
  religions: [],
  rationTypes: [],
  castes: [],
  sub_castes: [],
  wasteManagement: [],
});
//  const [boothChoices, setBoothChoices] = useState({
//   polling_booth_number: [],
//   polling_booth_name: [],
  
// });

// const fetchBooth = async () => {
//   if (!selectedGroupId) {
//     console.error("No group selected");
//     return;
//   }

//   try {
//     const response = await fetch(`${APIURL}/data_collection/api/polling-booths/available/?group_id=${selectedGroupId}`, {
//       method: 'GET',
//       headers: {
//         'Authorization': `Token ${token}`,
//       },
//     });
//     const data = await response.json();

//     if (data.status === "success") {
//       setApiChoices({
//         polling_booth_number: data.data.polling_booth_number || [],
//         polling_booth_name: data.data.polling_booth_name || [],
//       });
//     } else {
//       console.error("Error fetching booth:", data.message);
//     }
//   } catch (error) {
//     console.error("Error fetching booth:", error);
//   }
// };

// useEffect(() => {
//   if (selectedGroupId) {
//     fetchBooth();
//   }
// }, [selectedGroupId]);

const fetchChoices = async () => {
  try {
    const response = await fetch(`${APIURL}/data_collection/api/distinct-household-values/`, {
      method: 'GET',
      headers: {
        'Authorization': `Token ${token}`,
      },
    });
    const data = await response.json();

    setApiChoices({
      specialTreatments: data.data.special_treatment || [],
      petsTypes: data.data.pets || [],
      agricultureTypes: data.data.agriculture_details || [],
      sub_castes: data.data.sub_caste || [],
    });
  } catch (error) {
    console.error("Error fetching choices:", error);
  }
};

useEffect(() => {
  fetchChoices();
}, []);


// const toggleVerification = (contact) => {
//   setVerificationData({
//     individual_id: contact.id,
//     house_no: contact.house_no,
//     house_name: contact.house_name,
//   });
//   setVerificationModalOpen(true);
// };

const isSmallScreen = useMediaQuery('(max-width:650px)');



const toggleVerification = (contact) => {
  if (contact.verified) {
    alert('Already verified');
  } else {
    setVerificationData({
      individual_id: contact.id,
      house_no: contact.house_no,
      house_name: contact.house_name,
    });
    setVerificationModalOpen(true);
  }
};


const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);

};


  // const toggleVerificationOne = (id) => {
  //   setHouseData(prevData => 
  //     prevData.map(row => 
  //       row.id === id ? { ...row, verified: !row.verified } : row
  //     )
  //   );
  // };

const VerifyIcon = styled.span`
cursor: pointer;
`;

  const [houseModalOpen, setHouseModalOpen] = useState(false);
  
  const [newHouseData, setNewHouseData] = useState({
    house_no: '',
    house_name: '',
    family_name: '',
    land: 'No',
    house_status: 'Own',
    electricity: false,
    toilet: false,
    road_access: 'No',
    waste_management: 'No',
    drinking_water_source: 'Well',
    ration_card_type: 'APL',
    ration_card_number: '',
    way_to_house:'',
    special_care: 'No',
    special_treatment:'',
    agriculture: 'No',
    agriculture_details:'',
    pet: 'No',
    pets:'',
    financial_status: 'Middle Class',
    religion: '',
    caste: '',
    sub_caste: 'None',
    religious_organizations: '',
    no_name_on_ration_card: false,
    eligible_for_pension_but_not_receiving: false,
    remarks: ''
  });
  const [newSpecialTreatment, setNewSpecialTreatment] = useState('');
const [newPetsType, setNewPetsType] = useState('');
const [newAgricultureType, setNewAgricultureType] = useState('');
const [newSubCaste,setNewSubCaste] = useState('');
// const [newPollName,setNewPollName] = useState('');
// const [newPollNumber,setNewPollNumber] = useState('');

const initialHouseState = {
  house_no: '',
  house_name: '',
  family_name: '',
  land: 'No',
  house_status: 'Own',
  electricity: false,
  toilet: false,
  road_access: 'No',
  waste_management: 'No',
  drinking_water_source: 'Well',
  ration_card_type: 'APL',
  ration_card_number: '',
  way_to_house:'',
  special_care: 'No',
  special_treatment:'',
  agriculture: 'No',
  agriculture_details:'',
  pet: 'No',
  pets:'',
  financial_status: 'Middle Class',
  religion: '',
  caste: '',
  sub_caste: 'None',
  religious_organizations: '',
  no_name_on_ration_card: false,
  eligible_for_pension_but_not_receiving: false,
  remarks: ''
};

const openHouseModal = () => {
  setNewHouseData(initialHouseState);
  setFormErrors({}); 
  setHouseModalOpen(true);
};

const [houseData, setHouseData] = useState([]);
const [currentHouseData, setCurrentHouseData] = useState(null);
const [editHouseModalOpen, setEditHouseModalOpen] = useState(false);
const [familyData, setFamilyData] = useState([]);
const [newFamilyData, setNewFamilyData] = useState({

  family_name: '',
  remarks: '', 
});

const initialFamilyState = {
 
  family_name: '',
  remarks:''
};

const [houseFilters, setHouseFilters] = useState({
  house_no: '',
  house_name: '',
  head_of_household:'',
  total_house_members: '',
  total_house_voters: '',
  family_name: '',
  ration_card_type: '',
  remarks:'',
});

const [familyFilters, setFamilyFilters] = useState({
  family_name: '',
  total_family_households: '',
  total_family_members: '',
  total_family_voters: '',
  remarks:'',
});

const handleHouseFilterChange = (e, field) => {
  setHouseFilters({
    ...houseFilters,
    [field]: e.target.value,
  });
};

const handleFamilyFilterChange = (e, field) => {
  setFamilyFilters({
    ...familyFilters,
    [field]: e.target.value,
  });
};

const filteredHouseData = houseData.filter(house => {
  return (
    (house.house_no?.toString() || '').includes(houseFilters.house_no) &&
    (house.house_name?.toLowerCase() || '').includes(houseFilters.house_name.toLowerCase()) &&
    (house.total_house_members?.toString() || '').includes(houseFilters.total_house_members) &&
    (house.total_house_voters?.toString() || '').includes(houseFilters.total_house_voters) &&
    (house.family_name?.toLowerCase() || '').includes(houseFilters.family_name.toLowerCase()) &&
    (house.ration_card_type?.toLowerCase() || '').includes(houseFilters.ration_card_type.toLowerCase()) &&
    (house.remarks?.toLowerCase() || '').includes(houseFilters.remarks.toLowerCase())
  );
});

const filteredFamilyData = familyData.filter(family => {
  return (
    (family.family_name?.toLowerCase() || '').includes(familyFilters.family_name.toLowerCase()) &&
    (family.total_family_households?.toString() || '').includes(familyFilters.total_family_households) &&
    (family.total_family_members?.toString() || '').includes(familyFilters.total_family_members) &&
    (family.total_family_voters?.toString() || '').includes(familyFilters.total_family_voters) &&
    (family.remarks?.toString() || '').includes(familyFilters.remarks)
  );
}); 




const houseColumns = [
  {
    name: (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ marginBottom: '25px' }}>House No</div>
        <StyledInput
          type="text"
          value={houseFilters.house_no}
          onChange={e => handleHouseFilterChange(e, 'house_no')}
          width="125px"
        />
      </div>
    ),
    cell: (row) => (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', padding: '0px 0' }}>
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginRight: '8px', flex: 1 }}>
          {row.house_no}
        </div>
        {row.house_no !== 'No data' && row.house_name !== 'No data' && row.total_house_members !== 'No data' && row.total_house_voters !== 'No data' && row.family_name !== 'No data' && row.ration_card_type!== 'No data' && (
          <div style={{ flexShrink: 0 }}>
            <FaInfoCircle
              style={{ color: '#1d1160', cursor: 'pointer' }}
              size={17}
              onClick={(e) => {
                e.stopPropagation(); 
                handleHouseInfoClick(row); 
              }}
            />
          </div>
        )}
      </div>
    ),
    sortable: true,
    selector: (row) => row.house_no,
    width: '170px',
  },
  {
    name: (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ marginBottom: '25px' }}>House Name</div>
        <StyledInput
          type="text"
 
          value={houseFilters.house_name}
          onChange={e => handleHouseFilterChange(e, 'house_name')}
          width="125px"
        />
      </div>
    ),
    selector: (row) => row.house_name,
    sortable: true,
    width: '170px',
  },
  {
    name: (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ marginBottom: '25px' }}>Head of House</div>
        <StyledInput
          type="text"
 
          value={houseFilters.head_of_household_name}
          
          width="125px"
        />
      </div>
    ),
    selector: (row) => row.head_of_household_name,
    sortable: true,
    width: '170px',
  },
   {
    name: (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ marginBottom: '25px' }}>Total Members</div>
        <StyledInput
type="text"

value={houseFilters.total_house_members}
onChange={e => handleHouseFilterChange(e, 'total_house_members')}
width="125px"
/>
      </div>
    ),
    selector: (row) => row.total_house_members,
    sortable: true,
    width: '170px',
  },
  {
    name: (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ marginBottom: '25px' }}>Total Voters</div>
        <StyledInput
type="text"

value={houseFilters.total_house_voters}
onChange={e => handleHouseFilterChange(e, 'total_house_voters')}
width="125px"
/>
      </div>
    ),
    selector: (row) => row.total_house_voters,
    sortable: true,
    width: '170px', 
  },
  {
    name: (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ marginBottom: '25px' }}>Family Name</div>
        <StyledInput
type="text"

value={houseFilters.family_name}
onChange={e => handleHouseFilterChange(e, 'family_name')}
width="125px"
/>
      </div>
    ),
    selector: (row) => row.family_name,
    sortable: true,
    width: '170px',
  },
//   {
//     name: (
//       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//         <div style={{ marginBottom: '25px' }}>Ration Card Type</div>
       
// <StyledInput
// type="text"

// value={houseFilters.ration_card_type}
// onChange={e => handleHouseFilterChange(e, 'ration_card_type')}
// width="125px"
// />
//       </div>
//     ),
//     selector: (row) => row.ration_card_type,
//     sortable: true,
//     width: '170px', 
//   },
  {
    name: 'Edit',
    cell: (row, index) => (
      row.house_no !== 'No data' && row.house_name !== 'No data' && row.total_house_members !== 'No data' && row.total_house_voters !== 'No data' && row.family_name !== 'No data' && row.ration_card_type!== 'No data'  ? (
        <div onClick={() => editHouseRow(index)}>
          <FaEdit style={{ color: '#1d1160', cursor: 'pointer' }} size={20} />
        </div>
      ) : null 
    ),
    width: '60px',
  }, 
  {
    name: 'Delete',
    cell: (row, index) => (
      row.house_no !== 'No data' && row.house_name !== 'No data' && row.total_house_members !== 'No data' && row.gender !== 'No data' && row.family_name !== 'No data' && row.ration_card_type !== 'No data'  ? (
        <div onClick={() => deleteHouseRow(row.id)}>
          <FaTrash style={{ color: '#1d1160', cursor: 'pointer' }} size={20} />
        </div>
      ) : null 
    ),
    width: '70px',
  },  {
    name: (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ marginBottom: '25px' }}>Remarks</div>
        <StyledInput
          type="text"
          value={houseFilters.remarks}
          onChange={(e) => handleHouseFilterChange(e, 'remarks')}
          width="145px"
        />
      </div>
    ),
    selector: (row) => row.remarks,
    sortable: true,
    width: '190px',
  },
];

     
       
      
const familyColumns = [
  {
    name: (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ marginBottom: '25px' }}>Family Name</div>
        <StyledInput
          type="text"
          value={familyFilters.family_name}
          onChange={e => handleFamilyFilterChange(e, 'family_name')}
          width="200px"
        />
      </div>
    ),
    cell: (row) => (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', padding: '0px 0' }}>
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginRight: '8px', flex: 1 }}>
          {row.family_name}
        </div>
        {row.family_name !== 'No data' && row.total_family_households !== 'No data' && row.total_family_members !== 'No data' && row.total_family_voters !== 'No data' && (
          <div style={{ flexShrink: 0 }}>
            <FaInfoCircle
              style={{ color: '#1d1160', cursor: 'pointer' }}
              size={17}
              onClick={(e) => {
                e.stopPropagation();
                handleFamilyInfoClick(row); 
              }}
            />
          </div>
        )}
      </div>
    ),
    sortable: true,
    selector: (row) => row.family_name,
    width: '245px',
  },
  {
    name: (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ marginBottom: '25px' }}>Total Households</div>
        <StyledInput
          type="text"
          value={familyFilters.total_family_households}
          onChange={e => handleFamilyFilterChange(e, 'total_family_households')}
          width="200px"
        
        />
      </div>
    ),
    selector: (row) => row.total_family_households,
    sortable: true,
    width:'245px',
    
  },
  {
    name: (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ marginBottom: '25px' }}>Total Members</div>
        <StyledInput
          type="text"
          value={familyFilters.total_family_members}
          onChange={e => handleFamilyFilterChange(e, 'total_family_members')}
          width="200px"
        
        />
      </div>
    ),
    selector: (row) => row.total_family_members,
    sortable: true,
    width:'245px',
    
  },
  {
    name: (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ marginBottom: '25px' }}>Total Voters</div>
        <StyledInput
          type="text"
          value={familyFilters.total_family_voters}
          onChange={e => handleFamilyFilterChange(e, 'total_family_voters')}
          width="200px"
        
        />
      </div>
    ),
    selector: (row) => row.total_family_voters,
    sortable: true,
    width:'245px',
    
  },
  {
    name: 'Edit',
    cell: (row, index) => (
      row.family_name !== 'No data' && row.total_family_households !== 'No data' && row.total_family_members !== 'No data' && row.total_family_voters !== 'No data' ? (
        <div onClick={() => editFamilyRow(index)}>
          <FaEdit style={{ color: '#1d1160', cursor: 'pointer' }} size={20} />
        </div>
      ) : null 
    ),
    width: '60px',
  }, 
  {
    name: 'Delete',
    cell: (row, index) => (
      row.family_name !== 'No data' && row.total_family_households !== 'No data' && row.total_family_members !== 'No data' && row.total_family_voters !== 'No data' ? (
        <div onClick={() => deleteFamilyRow(row.id)}>
          <FaTrash style={{ color: '#1d1160', cursor: 'pointer' }} size={20} />
        </div>
      ) : null // Hide the Delete icon if the row has any field with 'No data'
    ),
    width: '70px',
  }, 
  {
    name: (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ marginBottom: '25px' }}>Remarks</div>
        <StyledInput
          type="text"
          value={familyFilters.remarks}
          onChange={(e) => handleFamilyFilterChange(e, 'remarks')}
          width="145px"
        />
      </div>
    ),
    selector: (row) => row.remarks,
    sortable: true,
    width: '190px',
  },
];



const openFamilyModal = () => {
  setNewFamilyData(initialFamilyState);
  setFormErrors({}); 
  setFamilyModalOpen(true);
};

const [currentFamilyData, setCurrentFamilyData] = useState(null);
const [familyModalOpen, setFamilyModalOpen] = useState(false);
const [editFamilyModalOpen, setEditFamilyModalOpen] = useState(false);



const handleAccordionChange = (panel) => (event, isExpanded) => {
  setExpanded(isExpanded ? panel : false);
};


useEffect(() => {
  if (modalOpen || editModalOpen) {
    setExpanded('basic');
  }
}, [modalOpen,editModalOpen]);


const formatDate = (date) => {
  if (!date) return '';
  const [year, month, day] = date.split('-');
  return `${day}-${month}-${year}`;
};

const displayDate = (date) => {
  if (!date) return '';
  const parts = date.split('-');
  if (parts.length !== 3) return date;
  const [day, month, year] = parts;
  return `${year}-${month}-${day}`;
};

// const addRow = async () => {
//   const mandatoryFields = ['group', 'name', 'gender', 'house_no', 'house_name', 'polling_booth_number', 'polling_booth_name','dob'];

//   const emptyMandatoryFields = mandatoryFields.filter(field => !newContact[field]);

//   if (emptyMandatoryFields.length > 0) {
//     alert(`Please fill all required fields: ${emptyMandatoryFields.join(', ')}`);
//     return;
//   }

//   const dataToSend = {
//     ...newContact,
//     dob: formatDate(newContact.dob),
//     eligible_to_vote: Boolean(newContact.eligible_to_vote),
//     pension: Boolean(newContact.pension),
//     // differently_abled:Boolean(newContact.differently_abled),

//   };
//   delete dataToSend.age;

//   try {
//     const response = await fetch(`${APIURL}/data_collection/api/add-individuals/?group_id=${newContact.group}`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Token ${token}`
//       },
//       body: JSON.stringify(dataToSend)
//     });

//     const data = await response.json();

//     if (data.status === 'success') {
//       alert(data.message || 'New data added');
//       fetchIndividuals(selectedGroupId);
//       fetchHouseData(selectedGroupId);
//       fetchFamilyData(selectedGroupId);
//       setContacts([...contacts, newContact]);
//       setModalOpen(false);
//       setNewContact(initialContactState);
//       try {
//         const rawResponse = await fetch(`${APIURL}/data_collection/api/individuals-raw/?group_id=${selectedGroupId}`, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Token ${token}`
//           }
//         });

//         const rawData = await rawResponse.json();
//         if (rawResponse.ok) {
//           console.log('Raw Data:', rawData);
//           // Handle the raw data as necessary, e.g., updating state or logging
//         } else {
//           console.error('Failed to fetch raw data:', rawData);
//           alert('Failed to fetch raw data');
//         }
//       } catch (error) {
//         console.error('Error fetching raw data:', error);
//         alert('An error occurred while fetching raw data');
//       }

//       setNewContact({
//         group: selectedGroupId,
//         polling_booth_number: '',
//         polling_booth_name: '',
//         sl_no: '',
//         house_no: '',
//         house_name: '',
//         name: '',
//         guardian_name: '',
//         guardian_relation: '',
//         dob: '',
//         age: '',
//         gender: 'choose an option',
//         epic_no: '',
//         education: 'choose an option',
//         occupation: '',
//         marital_status: 'Single',
//         expatriate: 'No',
//         vehicles: 'None',
//         differently_abled: false,
//         chronic_disease: '',
//         religion: 'Choose an option',
//         caste: '',
//         sub_caste: '',
//         religious_organizations: '',
//         social_organizations: '',
//         political_affiliation_party: '',
//         political_affiliation_status: '',
//         pension: false,
//         aadhaar_number: '',
//         email: '',
//         mobile_number: '',
//         eligible_to_vote: false
//       });
//     } else {
//       alert(data.message || 'Failed to add new data');
//     }
//   } catch (error) {
//     console.error('Error adding new contact:', error);
//     alert('An error occurred while adding the contact');
//   }
// };


const addRow = async () => {
  const mandatoryFields = ['group', 'name', 'gender', 'house_no', 'house_name', 'polling_booth_number', 'polling_booth_name','dob'];
  const emptyMandatoryFields = mandatoryFields.filter(field => !newContact[field]);

  if (emptyMandatoryFields.length > 0) {
    alert(`Please fill all required fields: ${emptyMandatoryFields.join(', ')}`);
    return;
  }

  const dataToSend = {
    ...newContact,
    
    name: newContact.name,
    guardian_name: newContact.guardian_name || null,
    guardian_relation: newContact.guardian_relation || null,
    house_no: newContact.house_no,
    house_name: newContact.house_name,
    gender: newContact.gender,
    dob: formatDate(newContact.dob),
    epic_no: newContact.epic_no || null,
    sl_no: newContact.sl_no || null,
    expatriate: newContact.expatriate || 'No',
    aadhaar_number: newContact.aadhaar_number || null,
    driving_licence_no: newContact.driving_licence_no || null,
    differently_abled: newContact.differently_abled || null,
    chronic_disease: newContact.chronic_disease || null,
    marital_status: newContact.marital_status || null,
    health_insurance: Boolean(newContact.health_insurance),
    social_insurance: Boolean(newContact.social_insurance),
    welfare_fund: newContact.welfare_fund,
    is_student: Boolean(newContact.is_student),
    education: newContact.education || null,
    field_of_education: newContact.field_of_education || null,
    occupation: newContact.occupation || null,
    profession: newContact.profession || null,
    religion: newContact.religion || null,
    caste: newContact.caste || null,
    sub_caste: newContact.sub_caste || null,
    religious_organizations: newContact.religious_organizations || null,
    social_organizations: newContact.social_organizations || null,
    political_affiliation_party: newContact.political_affiliation_party || null,
    political_affiliation_status: newContact.political_affiliation_status || null,
    political_affiliation_reason: newContact.political_affiliation_reason || null,
    mobile_number: newContact.mobile_number || null,
    email: newContact.email || null,
    is_active_on_social_media: Boolean(newContact.is_active_on_social_media),
    most_active_social_media: newContact.most_active_social_media || null,
    vehicles: newContact.vehicles || null,
    pension: Boolean(newContact.pension),
    remarks: newContact.remarks || null,
    eligible_to_vote: Boolean(newContact.eligible_to_vote),
  };

  delete dataToSend.age; 

  try {
  
  // if (newContact.polling_booth_number === 'Add New') {
  //   dataToSend.polling_booth_number = newContact.new_polling_booth_number || '';
  // }
  // if (newContact.polling_booth_name === 'Add New') {
  //   dataToSend.polling_booth_name = newContact.new_polling_booth_name || '';
  // }


    const response = await fetch(`${APIURL}/data_collection/api/add-individuals/?group_id=${newContact.group}&confirm_polling_booth=false`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
      body: JSON.stringify(dataToSend)
    });

    const data = await response.json();


    if (data.status === 'error_duplicates' && data.message) {
      const isConfirmed = window.confirm(data.message || `Polling booth conflict. Do you want to proceed?`);
      
      if (!isConfirmed) {
        return; 
      }

   
      const confirmedResponse = await fetch(`${APIURL}/data_collection/api/add-individuals/?group_id=${newContact.group}&confirm_polling_booth=true`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(dataToSend)
      });

      const confirmedData = await confirmedResponse.json();

      if (confirmedData.status === 'success') {
        alert(confirmedData.message || 'New data added successfully');
      } else {
        alert(confirmedData.message || 'Failed to add new data');
        return;
      }
    } else if (data.status === 'success') {
     
      
      alert(data.message || 'New data added successfully');
    } else {
      alert(data.message || 'Failed to add new data');
      return;
    }


    fetchIndividuals(selectedGroupId);
    fetchHouseData(selectedGroupId);
    fetchFamilyData(selectedGroupId);
    setContacts([...contacts, newContact]);
    setModalOpen(false);
    setNewContact(initialContactState);

    try {
      const rawResponse = await fetch(`${APIURL}/data_collection/api/individuals-raw/?group_id=${selectedGroupId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      });

      const rawData = await rawResponse.json();
      if (rawResponse.ok) {
        console.log('Raw Data:', rawData);
      } else {
        console.error('Failed to fetch raw data:', rawData);
        alert('Failed to fetch raw data');
      }
    } catch (error) {
      console.error('Error fetching raw data:', error);
      alert('An error occurred while fetching raw data');
    }
  } catch (error) {
    console.error('Error adding new contact:', error);
    alert('An error occurred while adding the contact');
  }
};





// const handleVerification = async () => {
//   try {
//     let addToExisting = false;


//     const response = await fetch(`${APIURL}/data_collection/api/verify-individuals/?individual_id=${verificationData.individual_id}&add_to_existing=${addToExisting}`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Token ${token}`
//       },
//       body: JSON.stringify({
//         verified: true,
//         house_no: verificationData.house_no,
//         house_name: verificationData.house_name
//       })
//     });

//     const initialData = await response.json();

//     if (initialData.status === 'error_duplicates') {
//       const isConfirmed = window.confirm(initialData.message || `Duplicate found. Do you want to proceed by adding to existing?`);

//       if (isConfirmed) {
//         addToExisting = true;
//       } else {
       
//         return;
//       }
//     }

//     const verificationResponse = await fetch(`${APIURL}/data_collection/api/verify-individuals/?individual_id=${verificationData.individual_id}&add_to_existing=${addToExisting}`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Token ${token}`
//       },
//       body: JSON.stringify({
//         verified: true,
//         house_no: verificationData.house_no,
//         house_name: verificationData.house_name
//       })
//     });

//     const verificationDataResponse = await verificationResponse.json();

//     if (verificationDataResponse.status === 'success') {
//       alert(verificationDataResponse.message);
//       fetchIndividuals(selectedGroupId);
//       fetchHouseData(selectedGroupId);
//       setContacts(contacts.map(contact =>
//         contact.id === verificationData.individual_id
//           ? { ...contact, verified: true }
//           : contact
//       ));
//       setVerificationModalOpen(false);
//     } else {
//       alert(verificationDataResponse.message || 'Verification failed');
//     }
//   } catch (error) {
//     console.error('Error verifying individual:', error);
//     alert('An error occurred while verifying the individual');
//   }
// };






// const handleVerification = async () => {
//   try {


//     const addToExisting = verificationData.house_no && verificationData.house_name;


//     const response = await fetch(`${APIURL}/data_collection/api/verify-individuals/?individual_id=${verificationData.individual_id}`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Token ${token}`
//       },
//       body: JSON.stringify({
//         add_to_existing:  addToExisting,
//         create_new_household: !verificationData. addToExisting,
//         fields: {
//           verified: true , 
//           house_no: verificationData.house_no,
//           house_name: verificationData.house_name
//         }
//       })
//     });

//     const data = await response.json();

//     if (data.status === 'success') {
//       alert(data.message);
//       setContacts(contacts.map(contact =>
//         contact.id === verificationData.individual_id
//           ? { ...contact, verified: false } 
//           : contact
//       ));
//       setVerificationModalOpen(false);
//     } else {
//       alert(data.message || 'Verification failed');
//     }
//   } catch (error) {
//     console.error('Error verifying individual:', error);
//     alert('An error occurred while verifying the individual');
//   }
// };


const fetchIndividuals = async (groupId) => {
  try {
    const response = await fetch(`${APIURL}/data_collection/api/individuals/?group_id=${groupId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${token}`
      },
    });
    const data = await response.json();
    if (data.status === "success") {
      setContacts(data.data || []);
    } else {
      setContacts([]);
    }
  } catch (error) {
    console.error("Error fetching individuals:", error);
    setContacts([]);
  }
};


useEffect(() => {
  if (selectedGroupId && typeof selectedGroupId === 'number') {
    fetchIndividuals(selectedGroupId);
    fetchHouseData(selectedGroupId);
    fetchFamilyData(selectedGroupId);
  }
}, [selectedGroupId]);



// const editRow = (index) => {
//   const contactToEdit = contacts[index];
//   setCurrentContact({ 
//     ...contactToEdit, 
//     group: contactToEdit.group || selectedGroupId, 
//     index 
//   });
//   setEditModalOpen(true);
// };

const editRow = async (index) => {
  setFormErrors({}); 
  const contactToEdit = contacts[index];  
  const individualId = contactToEdit.id;  

  try {
    const response = await fetch(`${APIURL}/data_collection/api/individual-details/?individual_id=${individualId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${token}`, 
      },
    });
    
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();

    if (data.status === "success") {
      const contactToEdit = data.data || {}; 
      setCurrentContact({
        ...contactToEdit,
        group: contactToEdit.group || selectedGroupId, 
        index,
      });
      setEditModalOpen(true); 
    } else {
      console.error('Failed to fetch individual details');
    }
  } catch (error) {
    console.error("Error fetching individual details:", error);
  }
};

// const   updateRow = async () => {
//   try {
//     const statusMap = {
//       "Inactive": 0,
//       "Active": 1,
//     };  


//     console.log(currentContact.eligible_to_vote);
//     console.log(Boolean(currentContact.eligible_to_vote));


//     let current_household_id = currentContact.household ? currentContact.household.id : null;
 
//     const updatedContact = {
//       ...currentContact,
//       status: statusMap[currentContact.status],
//       dob: formatDate(currentContact.dob),
 
//       household: current_household_id,
     
     
//        eligible_to_vote: Boolean(currentContact.eligible_to_vote),
//         pension: Boolean(currentContact.pension),
  

//     };

//     delete updatedContact.age;

//     const updateResponse = await fetch(`${APIURL}/data_collection/api/update-individuals/?individual_id=${updatedContact.id}`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Token ${token}`
//       },
//       body: JSON.stringify(updatedContact)
//     });

//     const updateData = await updateResponse.json();

//     if (updateData.status === 'success') {
     
//       const fetchResponse = await fetch(`${APIURL}/data_collection/api/individuals/?group_id=${selectedGroupId}`, {
//         method: "GET",
//         headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Token ${token}`
//       },
//       });
//       fetchHouseData(selectedGroupId);
//       fetchFamilyData(selectedGroupId);
      
//       const fetchData = await fetchResponse.json();
      
//       if (fetchData.status === "success") {
//         setContacts(fetchData.data || []);
//       } else {
//         setContacts([]);
//       }

//       setEditModalOpen(false);
//       alert(updateData.message);
//     } else {
//       alert(updateData.message);
//     }
//   } catch (error) {
//     console.error('Update error:', error);
//     alert('Updation failed');
//   }
// };


const updateRow = async () => {
  try {
    const statusMap = {
      "Inactive": 0,
      "Active": 1,
    };

    console.log(currentContact.eligible_to_vote);
    console.log(Boolean(currentContact.eligible_to_vote));

    let current_household_id = currentContact.household ? currentContact.household.id : null;

    const updatedContact = {
      ...currentContact,
      status: statusMap[currentContact.status],
      household: current_household_id,
      name: currentContact.name,
      guardian_name: currentContact.guardian_name || null,
      guardian_relation: currentContact.guardian_relation || null,
      house_no: currentContact.house_no,
      house_name: currentContact.house_name,
      gender: currentContact.gender,
      dob: formatDate(currentContact.dob),
      epic_no: currentContact.epic_no || null,
      sl_no: currentContact.sl_no || null,
      expatriate: currentContact.expatriate || 'No',
      aadhaar_number: currentContact.aadhaar_number || null,
      driving_licence_no: currentContact.driving_licence_no || null,
      differently_abled: currentContact.differently_abled || null,
      chronic_disease: currentContact.chronic_disease || null,
      marital_status: currentContact.marital_status || null,
      health_insurance: Boolean(currentContact.health_insurance) ,
      social_insurance: Boolean(currentContact.social_insurance) ,
      welfare_fund: currentContact.welfare_fund,
      is_student: Boolean(currentContact.is_student),
      education: currentContact.education || null,
      field_of_education: currentContact.field_of_education || null,
      occupation: currentContact.occupation || null,
      profession: currentContact.profession || null,
      religion: currentContact.religion || null,
      caste: currentContact.caste || null,
      sub_caste: currentContact.sub_caste || null,
      religious_organizations: currentContact.religious_organizations || null,
      social_organizations: currentContact.social_organizations || null,
      political_affiliation_party: currentContact.political_affiliation_party || null,
      political_affiliation_status: currentContact.political_affiliation_status || null,
      political_affiliation_reason: currentContact.political_affiliation_reason || null,
      mobile_number: currentContact.mobile_number || null,
      email: currentContact.email || null,
      is_active_on_social_media: Boolean(currentContact.is_active_on_social_media),
      most_active_social_media: currentContact.most_active_social_media || null,
      vehicles: currentContact.vehicles || null,
      pension: Boolean(currentContact.pension),
      remarks: currentContact.remarks || null,
      eligible_to_vote: Boolean(currentContact.eligible_to_vote),
    };

    delete updatedContact.age;
  
  //  if (currentContact.polling_booth_number === 'Add New') {
  //   updatedContact.polling_booth_number = currentContact.new_polling_booth_number || '';
  // }
  // if (currentContact.polling_booth_name === 'Add New') {
  //   updatedContact.polling_booth_name = currentContact.new_polling_booth_name || '';
  // }
 
   
    const updateResponse = await fetch(`${APIURL}/data_collection/api/update-individuals/?individual_id=${updatedContact.id}&confirm_polling_booth=false`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      },
      body: JSON.stringify(updatedContact),
    });

    const updateData = await updateResponse.json();

    if (updateData.status === 'error_duplicates' && updateData.message) {
      const isConfirmed = window.confirm(updateData.message || 'Polling booth conflict. Do you want to proceed?');

      if (!isConfirmed) {
        return; 
      }


      const confirmedUpdateResponse = await fetch(`${APIURL}/data_collection/api/update-individuals/?individual_id=${updatedContact.id}&confirm_polling_booth=true`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
        body: JSON.stringify(updatedContact),
      });

      const confirmedUpdateData = await confirmedUpdateResponse.json();

      if (confirmedUpdateData.status === 'success') {
        alert(confirmedUpdateData.message || 'Contact updated successfully');
      } else {
        alert(confirmedUpdateData.message || 'Failed to update contact');
        return;
      }
    } else if (updateData.status === 'success') {
      alert(updateData.message || 'Contact updated successfully');
    } else {
      alert(updateData.message || 'Failed to update contact');
      return;
    }

    const fetchResponse = await fetch(`${APIURL}/data_collection/api/individuals/?group_id=${selectedGroupId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      },
    });

    fetchHouseData(selectedGroupId);
    fetchFamilyData(selectedGroupId);

    const fetchData = await fetchResponse.json();

    if (fetchData.status === 'success') {
      setContacts(fetchData.data || []);
    } else {
      setContacts([]);
    }

    setEditModalOpen(false);
  } catch (error) {
    console.error('Update error:', error);
    alert('Updation failed');
  }
};

  
  
  

// const handleChange = (e, setContact) => {
//   const { name, value } = e.target;
//   setContact(prevState => {
//     const newState = { ...prevState };

//     if (name === 'dob') {
//       newState.dob = value;
      
//       // Calculate ageo
      
//       const [year, month, day] = value.split('-');
//       const dob = new Date(year, month - 1, day);
//       const today = new Date();
//       let age = today.getFullYear() - dob.getFullYear();
//       const monthDiff = today.getMonth() - dob.getMonth();
//       if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
//         age--;
//       }
//       newState.age = age.toString();
//     } else if (name === 'age') {
//       newState.age = value;
      
//       // Calculate DOB
//       const today = new Date();
//       const dob = new Date(today.getFullYear() - parseInt(value), today.getMonth(), today.getDate());
//       const year = dob.getFullYear();
//       const month = String(dob.getMonth() + 1).padStart(2, '0');
//       const day = String(dob.getDate()).padStart(2, '0');
//       newState.dob = `${year}-${month}-${day}`;
//     } else if (name === 'eligible_to_vote' || name === 'pension' || name === 'differently_abled') {
      
//       newState[name] = value;
//     } else {
//       newState[name] = value;
//     }
    
//     return newState;
//   });
// };


const handleChange = (e, setContact) => {
  const { name, value } = e.target;
  setContact(prevState => {
    const newState = { ...prevState };

    // Handle date and age calculations
    if (name === 'dob') {
      newState.dob = value;
      
      // Calculate age
      const [year, month, day] = value.split('-');
      const dob = new Date(year, month - 1, day);
      const today = new Date();
      let age = today.getFullYear() - dob.getFullYear();
      const monthDiff = today.getMonth() - dob.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
        age--;
      }
      newState.age = age.toString();
    }
    else if (name === 'age') {
      newState.age = value;
      
      // Calculate DOB
      const today = new Date();
      const dob = new Date(today.getFullYear() - parseInt(value), today.getMonth(), today.getDate());
      const year = dob.getFullYear();
      const month = String(dob.getMonth() + 1).padStart(2, '0');
      const day = String(dob.getDate()).padStart(2, '0');
      newState.dob = `${year}-${month}-${day}`;
    }
    // Handle special fields
    else if (name === 'special_treatment') {
      newState.special_treatment = value;
      if (value !== 'Add New') {
        setNewSpecialTreatment('');
      }
    }
    else if (name === 'newSpecialTreatment') {
      newState.special_treatment = value;
    }
    else if (name === 'sub_caste') {
      newState.sub_caste = value;
      if (value !== 'Add New') {
        setNewSubCaste('');
      }
    }
    else if (name === 'newSubCaste') {
      setNewSubCaste(value);
      newState.sub_caste = value;
    }
    else if (name === 'pets') {
      newState.pets = value;
      if (value !== 'Add New') {
        setNewPetsType('');
      }
    }
    else if (name === 'newPetsType') {
      newState.pets = value;
    }
    else if (name === 'agriculture_details') {
      newState.agriculture_details = value;
      if (value !== 'Add New') {
        setNewAgricultureType('');
      }
    }
    else if (name === 'newAgricultureType') {
      newState.agriculture_details = value;
    }
    else if (name === 'otherReligiousOrg') {
      newState.religious_organizations = value;
    }
    else if (['eligible_to_vote', 'pension', 'differently_abled'].includes(name)) {
      newState[name] = value;
    }
    else {
      newState[name] = value;
    }

    return newState;
  });
};

  // const deleteRow = (index) => {
  //   if (window.confirm('Are you sure you want to delete this contact?')) {
  //     const updatedContacts = contacts.filter((_, i) => i !== index);..
  //     setContacts(updatedContacts);
  //   }
  // };




  

  
    const deleteRow = async (individualId) => {
      if (window.confirm('Are you sure you want to delete this contact?')) {
        try {
          const response = await fetch(`${APIURL}/data_collection/api/individuals/delete/?individual_id=${individualId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`
            },
            body: JSON.stringify({ id: individualId, confirm:true })
          });
    
          const data = await response.json();
    
          if (data.status === 'success') {
            alert(data.message);
            fetchIndividuals(selectedGroupId);
            fetchHouseData(selectedGroupId);
      fetchFamilyData(selectedGroupId);
           
            setContacts(contacts.filter(contact => contact.id !== individualId));
          } else {
            alert(data.message || 'An error occurred while marking the individual as inactive');
          }
        } catch (error) {
          console.error('Error deleting individual:', error);
          alert('An error occurred while deleting the contact');
        }
      }
    };
 
  
  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

 
  

  const addHouseData = async () => {
    const mandatoryFields = ['house_no', 'house_name', 'family_name',];
    const emptyMandatoryFields = mandatoryFields.filter(field => !newHouseData[field]);
    
    if (emptyMandatoryFields.length > 0) {
      alert(`Please fill all required fields: ${emptyMandatoryFields.join(', ')}`);
      return;
    }
  
    if (!/^[1-9]\d*$/.test(newHouseData.house_no)) {
      alert('House number must be a positive integer');
      return;
    }
  
      try {
    const houseDataToSubmit = {
      ...newHouseData,
      family_name: newHouseData.family_name || null,
      house_no: parseInt(newHouseData.house_no, 10),
      house_name: newHouseData.house_name,
      land: Boolean(newHouseData.land),
      house_status: newHouseData.house_status || null,
      electricity: Boolean(newHouseData.electricity),
      drinking_water_source: newHouseData.drinking_water_source || null,
      toilet: Boolean(newHouseData.toilet),
      waste_management: newHouseData.waste_management || null,
      road_access: newHouseData.road_access || null,
      ration_card_type: newHouseData.ration_card_type || null,
      ration_card_number: newHouseData.ration_card_number || null,
      no_name_on_ration_card: Boolean(newHouseData.no_name_on_ration_card),
      eligible_for_pension_but_not_receiving: Boolean(newHouseData.eligible_for_pension_but_not_receiving),
      special_treatment: newHouseData.special_treatment || null,
      agriculture_details: newHouseData.agriculture_details || null,
      pets: newHouseData.pets || null,
      financial_status: newHouseData.financial_status || null,
      religion: newHouseData.religion || null,
      caste: newHouseData.caste || null,
      sub_caste: newHouseData.sub_caste || null,
      religious_organizations: newHouseData.religious_organizations || null,
      remarks: newHouseData.remarks || null,
      group: selectedGroupId
    };

   
    if (newHouseData.special_treatment === 'Add New') {
      houseDataToSubmit.special_treatment = newSpecialTreatment;
    }
    if (newHouseData.pets === 'Add New') {
      houseDataToSubmit.pets = newPetsType;
    }
    if (newHouseData.agriculture_details === 'Add New') {
      houseDataToSubmit.agriculture_details = newAgricultureType;
    }
    if (newHouseData.sub_caste === 'Add New' || SUB_CASTE_CHOICES.every(choice => choice !== newHouseData.sub_caste)) {
      houseDataToSubmit.sub_caste = newSubCaste;
    }
    const response = await fetch(`${APIURL}/data_collection/api/households/?group_id=${selectedGroupId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
      body: JSON.stringify(houseDataToSubmit)
    });

    const data = await response.json();
    
    if (data.status === 'success') {
    
      if (newSpecialTreatment && !SPECIAL_TREATMENT_CHOICES.includes(newSpecialTreatment)) {
        SPECIAL_TREATMENT_CHOICES.push(newSpecialTreatment);
      }
      if (newPetsType && !PETS_TYPES_CHOICES.includes(newPetsType)) {
        PETS_TYPES_CHOICES.push(newPetsType);
      }
      if (newAgricultureType && !AGRICULTURE_TYPES_CHOICES.includes(newAgricultureType)) {
        AGRICULTURE_TYPES_CHOICES.push(newAgricultureType);
      }
      if (newSubCaste && !SUB_CASTE_CHOICES.includes(newSubCaste) && newSubCaste !== 'Add New') {
        SUB_CASTE_CHOICES.splice(SUB_CASTE_CHOICES.length - 1, 0, newSubCaste);
      }

      alert(data.message || 'New house data added');
      fetchIndividuals(selectedGroupId);
      fetchHouseData(selectedGroupId);
      fetchFamilyData(selectedGroupId);
      setHouseModalOpen(false);
      setNewHouseData(initialHouseState);
      setNewHouseData({
        house_no: '',
        house_name: '',
        family_name: '',
        land: 'No',
        house_status: 'Own',
        electricity: false,
        toilet: false,
        road_access: 'No',
        waste_management: 'No',
        drinking_water_source: 'Well',
        ration_card_type: 'APL',
        ration_card_number: '',
        way_to_house:'',
        special_care: 'No',
        special_treatment:'',
        agriculture: 'No',
        agriculture_details:'',
        pet: 'No',
        pets:'',
        financial_status: 'Middle Class',
        religion: '',
        caste: '',
        sub_caste: 'None',
        religious_organizations: '',
        no_name_on_ration_card: false,
        eligible_for_pension_but_not_receiving: false,
        remarks: ''
      });
      setNewSpecialTreatment('');
      setNewPetsType('');
      setNewAgricultureType('');
      setNewSubCaste('');
    } else {
      alert(data.message || 'Failed to add new house data');
    }
  } catch (error) {
    console.error('Error adding new house data:', error);
    alert('An error occurred while adding the house data');
  }
};


  
  const fetchHouseData = async (groupId) => {
    try {
      const response = await fetch(`${APIURL}/data_collection/api/households/?group_id=${groupId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Token ${token}`
        },
      });
      const data = await response.json();
      if (data.status === "success") {
        setHouseData(data.data || []);
      } else {
        setHouseData([]);
      }
    } catch (error) {
      console.error("Error fetching house data:", error);
      setHouseData([]);
    }
  };


  const customStyles = {
    headCells: {
      style: {
        padding: '10px 8px', 
        backgroundColor: '#344771',
        border: '2px solid #FFFFFF',
        color: '#ffffff',
        fontWeight: 'bold',
        fontSize: '14px',
        height: 'auto', 
      },
    },
    cells: {
      style: {

        border: '2px solid #FFFFFF',
        backgroundColor: '#DCE9F7',
      },
    },
  };
  

 
  const updateHouseData = async () => {
    const mandatoryFields = ['group', 'house_no', 'house_name', 'family_name'];
    const emptyMandatoryFields = mandatoryFields.filter(field => !currentHouseData[field]);
    
    if (emptyMandatoryFields.length > 0) {
      alert(`Please fill all required fields: ${emptyMandatoryFields.join(', ')}`);
      return;
    }
      
      try {
        const houseDataToSubmit = {
          ...currentHouseData,
          family_name: currentHouseData.family_name || null,
          house_no: parseInt(currentHouseData.house_no, 10),
          land: Boolean(currentHouseData.land),
          house_status: currentHouseData.house_status || null,
          electricity: Boolean(currentHouseData.electricity),
          drinking_water_source: currentHouseData.drinking_water_source || null,
          house_status: currentHouseData.house_status || null,
          toilet: Boolean(currentHouseData.toilet),
          waste_management: currentHouseData.waste_management || null,
          road_access: currentHouseData.road_access || null,
          ration_card_type: currentHouseData.ration_card_type || null,
          ration_card_number: currentHouseData.ration_card_number || null,
          no_name_on_ration_card: Boolean(currentHouseData.no_name_on_ration_card),
          eligible_for_pension_but_not_receiving: Boolean(currentHouseData.eligible_for_pension_but_not_receiving),
          special_treatment: currentHouseData.special_treatment || null,
          agriculture_details: currentHouseData.agriculture_details || null,
          pets: currentHouseData.pets || null,
          financial_status: currentHouseData.financial_status || null,
          religion: currentHouseData.religion || null,
          caste: currentHouseData.caste || null,
          sub_caste: currentHouseData.sub_caste || null,
          religious_organizations: currentHouseData.religious_organizations || null,
          remarks: currentHouseData.remarks || null,
          group: selectedGroupId
        };
    
      
        if (currentHouseData.special_treatment === 'Add New') {
          houseDataToSubmit.special_treatment = newSpecialTreatment;
        }
        if (currentHouseData.pets === 'Add New') {
          houseDataToSubmit.pets = newPetsType;
        }
        if (currentHouseData.agriculture_details === 'Add New') {
          houseDataToSubmit.agriculture_details = newAgricultureType;
        }
    
      const response = await fetch(`${APIURL}/data_collection/api/households/?household_id=${currentHouseData.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },  
        body: JSON.stringify({
         family_name: currentHouseData.family_name || null,
          house_no: parseInt(currentHouseData.house_no, 10),
          house_name: currentHouseData.house_name,
          land: Boolean(currentHouseData.land),
          house_status: currentHouseData.house_status || null,
          electricity: Boolean(currentHouseData.electricity),
          drinking_water_source: currentHouseData.drinking_water_source || null,
          toilet: Boolean(currentHouseData.toilet),
          waste_management: currentHouseData.waste_management || null,
          road_access: currentHouseData.road_access || null,
          ration_card_type: currentHouseData.ration_card_type || null,
          ration_card_number: currentHouseData.ration_card_number || null,
          no_name_on_ration_card: Boolean(currentHouseData.no_name_on_ration_card),
          eligible_for_pension_but_not_receiving: Boolean(currentHouseData.eligible_for_pension_but_not_receiving),
          special_treatment: currentHouseData.special_treatment || null,
          agriculture_details: currentHouseData.agriculture_details || null,
          pets: currentHouseData.pets || null,
          financial_status: currentHouseData.financial_status || null,
          religion: currentHouseData.religion || null,
          caste: currentHouseData.caste || null,
          sub_caste: currentHouseData.sub_caste || null,
          religious_organizations: currentHouseData.religious_organizations || null,
          remarks: currentHouseData.remarks || null,
          group: selectedGroupId


        })
      });
  
      const data = await response.json();
  
      if (data.status === 'success') {

           
      if (newSpecialTreatment && !SPECIAL_TREATMENT_CHOICES.includes(newSpecialTreatment)) {
        SPECIAL_TREATMENT_CHOICES.push(newSpecialTreatment);
      }
      if (newPetsType && !PETS_TYPES_CHOICES.includes(newPetsType)) {
        PETS_TYPES_CHOICES.push(newPetsType);
      }
      if (newAgricultureType && !AGRICULTURE_TYPES_CHOICES.includes(newAgricultureType)) {
        AGRICULTURE_TYPES_CHOICES.push(newAgricultureType);
      }


        alert(data.message || 'Household data updated successfully');
        fetchIndividuals(selectedGroupId);
        fetchHouseData(selectedGroupId); 
        fetchFamilyData(selectedGroupId);
        setEditHouseModalOpen(false);
        setCurrentHouseData(null);
      } else {
        alert(data.message || 'Failed to update household data');
      }
    } catch (error) {
      console.error('Error updating household data:', error);
      alert('An error occurred while updating the household data');
    }
  };


  // const editHouseRow = (row) => {
  //   setFormErrors({}); 
  //   setCurrentHouseData({ ...row });
  //   setEditHouseModalOpen(true);
  // };

  const editHouseRow = (index) => {
    const houseToEdit = houseData[index];
    setFormErrors({}); 
    setCurrentHouseData({ 
      ...houseToEdit,
      group: selectedGroup,
      index 
    });
    setEditHouseModalOpen(true);
  };
    
    // const deleteHouseRow = (index) => {
    //   if (window.confirm('Are you sure you want to delete this house data?')) {
    //     const updatedHouseData = houseData.filter((_, i) => i !== index);
    //     setHouseData(updatedHouseData);
    //   }
    // };


   const deleteHouseRow = async (householdId) => {
  if (window.confirm('Are you sure you want to delete this house data?')) {
    try {
      const response = await fetch(`${APIURL}/data_collection/api/households/delete/?household_id=${householdId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify({ id: householdId, confirm: true })
      });

      const data = await response.json();

      if (data.status === 'success') {
        alert(data.message || 'House data deleted successfully');
        fetchIndividuals(selectedGroupId);
        fetchHouseData(selectedGroupId);
  fetchFamilyData(selectedGroupId);
        setHouseData(houseData.filter(house => house.id !== householdId));
      } else {
        alert(data.message || 'An error occurred while deleting the house data');
      }
    } catch (error) {
      console.error('Error deleting house data:', error);
      alert('An error occurred while deleting the house data');
    }
  }
};




    const addFamilyData = async () => {
      const mandatoryFields = ['family_name'];
      const emptyMandatoryFields = mandatoryFields.filter(field => !newFamilyData[field]);
      
      if (emptyMandatoryFields.length > 0) {
        alert(`Please fill all required fields: ${emptyMandatoryFields.join(', ')}`);
        return;
      }
    
      try {
        const familyDataToSubmit = {
          ...newFamilyData,
          group: selectedGroupId, 
          remarks: newFamilyData.remarks || null,
          family_name: newFamilyData.family_name,

        };
    
        const response = await fetch(`${APIURL}/data_collection/api/family/?group_id=${selectedGroupId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
          },
          body: JSON.stringify(familyDataToSubmit)
        });
    
        const data = await response.json();
        
        if (data.status === 'success') {
          alert(data.message || 'New family data added');
          fetchIndividuals(selectedGroupId);
          fetchHouseData(selectedGroupId);
    fetchFamilyData(selectedGroupId);
          setFamilyModalOpen(false);
          setNewHouseData(initialHouseState);
          setNewFamilyData({
         
             family_name: '',
            remarks:''
          });
        } else {
          alert(data.message || 'Failed to add new family data');
        }
      } catch (error) {
        console.error('Error adding new family data:', error);
        alert('An error occurred while adding the family data');
      }
    };
    

    const fetchFamilyData = async (groupId) => {
      try {
        const response = await fetch(`${APIURL}/data_collection/api/family/?group_id=${groupId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Token ${token}`
          },
        });
        const data = await response.json();
        if (data.status === "success") {
          setFamilyData(data.data || []);
        } else {
          setFamilyData([]);
        }
      } catch (error) {
        console.error("Error fetching family data:", error);
        setFamilyData([]);
      }
    };


    const editFamilyRow = (index) => {
      const familyToEdit = familyData[index];
      setFormErrors({}); 
      setCurrentFamilyData({ 
        ...familyToEdit,
        group: selectedGroup,
        index 
      });
      setEditFamilyModalOpen(true);
    };
    
    const updateFamilyData = async () => {
      const mandatoryFields = ['family_name'];
      const emptyMandatoryFields = mandatoryFields.filter(field => !currentFamilyData[field]);
      
      if (emptyMandatoryFields.length > 0) {
        alert(`Please fill all required fields: ${emptyMandatoryFields.join(', ')}`);
        return;
      }
      
      if (!currentFamilyData.id) {
        alert('Family ID is missing. Cannot update.');
        return;
      }
    
      try {
        const response = await fetch(`${APIURL}/data_collection/api/family/?family_id=${currentFamilyData.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
          },
          body: JSON.stringify({
            family_name: currentFamilyData.family_name,
            remarks: currentFamilyData.remarks || null,
          })
        });
    
        const data = await response.json();
        
        if (data.status === 'success') {
          alert(data.message || 'Family data updated successfully');
          
          const updatedFamilyData = familyData.map(family => 
            family.id === currentFamilyData.id ? {...family, ...data.data.data} : family
          );
          setFamilyData(updatedFamilyData);
          fetchHouseData(selectedGroupId); 
          setEditFamilyModalOpen(false);
          setCurrentFamilyData(null);
        } else {
          alert(data.message || 'Failed to update family data');
        }
      } catch (error) {
        console.error('Error updating family data:', error);
        alert('An error occurred while updating the family data');
      }
    };


    // const deleteFamilyRow = (index) => {
    //   if (window.confirm('Are you sure you want to delete this family data?')) {
    //     const updatedFamilyData = familyData.filter((_, i) => i !== index);
    //     setFamilyData(updatedFamilyData);
    //   }
    // };



      const deleteFamilyRow = async (familyId) => {
  if (window.confirm('Are you sure you want to delete this Family?')) {
    try {
      const response = await fetch(`${APIURL}/data_collection/api/family/delete/?family_id=${familyId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify({ id: familyId, confirm: true })
      });

      const data = await response.json();

      if (data.status === 'success') {
        alert(data.message || 'Family deleted successfully');
        fetchIndividuals(selectedGroupId);
        fetchHouseData(selectedGroupId);
  fetchFamilyData(selectedGroupId);
        setFamilyData(familyData.filter(family => family.id !== familyId));
      } else {
        alert(data.message || 'An error occurred while deleting the Family data');
      }
    } catch (error) {
      console.error('Error deleting Family data:', error);
      alert('An error occurred while deleting the Family data');
    }
  }
};

    // async function handleFileUpload() {
    //   const input = document.createElement('input');
    //   input.type = 'file';
    //   input.accept = '.xlsx,.xls,.csv';
    
    //   input.onchange = async (event) => {
    //     const file = event.target.files[0];
    //     if (!file) {
    //       console.error('No file selected');
    //       return;
    //     }
    
    //     const formData = new FormData();
    //     formData.append('file', file);
    //     formData.append('group_id', selectedGroupId); // Using the selectedGroupId from the component's state
    
    //     try {
    //       const response = await axios.post(`${APIURL}/data_collection/api/upload-individuals/`, formData, {
    //         headers: {
    //           'Content-Type': 'multipart/form-data',
    //           'Authorization': `Token ${token}` // Using the token from localStorage
    //         }
    //       });
    
    //       if (response.data.status === 'success') {
    //         console.log(response.data.message);
    //         alert(response.data.message); // Show success message to the user
    //         // Optionally, you can update the contacts state here if needed
    //         fetchIndividuals(selectedGroupId); // Refresh the individuals list
    //       } else {
    //         console.error('Upload failed:', response.data.message);
    //         alert(`Upload failed: ${response.data.message}`); // Show error message to the user
    //       }
    //     } catch (error) {
    //       console.error('Error uploading file:', error);
    //       alert('Error uploading file. Please try again.'); // Show general error message
    //     }
    //   };
    
    //   input.click();
    // }


  
    const handleFormTabChange = (event, newIndex) => {
      setFormTabIndex(newIndex);
    };
  

    useEffect(() => {
      if (!modalOpen && !editModalOpen) {
        setFormTabIndex(0);
      }
    }, [modalOpen, editModalOpen]);

  
    const renderFormFields = (fields, contactData, setContactData, isEditMode = false) => {
      if (!contactData) return null;
    
      return fields.map((field) => (
        <Grid item xs={6} key={field}>
          <Typography variant="body1" className="field-label">
            {field === 'sl_no' ? 'Sl.No (as per voters list)' :
            
             field.replace(/_/g, ' ').toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}
            {['name', 'gender', 'house_no', 'house_name', 'polling_booth_number', 'polling_booth_name','dob'].includes(field) && 
             <span style={{ color: 'red' }}> *</span>}
          </Typography>
          {(field === 'house_no' || field === 'house_name') && isEditMode ? (
        <TextField
          name={field}
          type="text"
          fullWidth
          variant="outlined"
          value={contactData[field] || ''}
          className="form-field"
          InputProps={{
            readOnly: true,
          }}
        />
      ) : field === 'house_no' || field === 'house_name' ? (
        <TextField
          name={field}
          type="text"
          fullWidth
          variant="outlined"
          value={contactData[field] || ''}
          onChange={(e) => handleChange(e, setContactData)}
          className="form-field"
          error={!!formErrors[field]}
          helperText={formErrors[field]}
          required
        />
      ) :  field === 'expatriate' ? ( 
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={contactData[field] || ''}
              onChange={(e) => handleChange(e, setContactData)}
              className="form-field"
              error={!!formErrors[field]}
            >
              {EXPATRIATE_STATUS_CHOICES.map((choice) => (
                <MenuItem key={choice} value={choice}>{choice}</MenuItem>
              ))}
            </Select>
          ) : field === 'name' || field === 'guardian_name'   || field === 'guardian_relation' ? (
            <TextField
              name={field}
              type="text"
              fullWidth
              variant="outlined"
              value={contactData[field]}
              onChange={(e) => {
                const value = e.target.value;
                if (/^[a-zA-Z\s]*$/.test(value)) {
                  handleChange(e, setContactData);
                  setFormErrors(prevErrors => ({
                    ...prevErrors,
                    [field]: ''
                  }));
                } else {
                  setFormErrors(prevErrors => ({
                    ...prevErrors,
                    [field]: `${field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())} must contain only alphabetic characters and spaces.`
                  }));
                }
              }}
              className="form-field"
              error={!!formErrors[field]}
              helperText={formErrors[field]}
              inputProps={{ pattern: "[a-zA-Z\s]*" }}
            />
          ) : field === 'dob' ? (
            <TextField
              name={field}
              type="date"
              fullWidth
              variant="outlined"
              value={contactData[field] || ''}
              onChange={(e) => handleChange(e, setContactData)}
              className="form-field"
              error={!!formErrors[field]}
              helperText={formErrors[field]}
              InputLabelProps={{ shrink: true }}
            />
          ) : field === 'age' || field === 'sl_no' || field === 'house_no' || field === 'mobile_number'  || field === 'aadhaar_number' || field === 'polling_booth_number' ? (
            <TextField
              name={field}polling_booth_number
              type="text"
              fullWidth
              variant="outlined"
              value={contactData[field]}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  handleChange(e, setContactData);
                  setFormErrors(prevErrors => ({
                    ...prevErrors,
                    [field]: ''
                  }));
                } else {
                  setFormErrors(prevErrors => ({
                    ...prevErrors,
                    [field]: `${field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())} must contain only digits.`
                  }));
                }
              }}
              className="form-field"
              error={!!formErrors[field]}
              helperText={formErrors[field]}
              inputProps={{ 
                pattern: "\\d*",
                readOnly: contactData.verified && isEditMode && (field === 'house_no' || field === 'house_name')
              }}
            />
          ) : ['is_student'].includes(field) ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={contactData[field] ? 'true' : 'false'}
              onChange={(e) => {
                const valuest = e.target.value === 'true';
                setContactData(prevState => ({
                  ...prevState,
                  is_student: valuest
                 
                }));
              }}
              className="form-field"
            >
              <MenuItem value="false">No</MenuItem>
              <MenuItem value="true">Yes</MenuItem>
            </Select>
          ) : ['health_insurance'].includes(field) ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={contactData[field] ? 'true' : 'false'}
              onChange={(e) => {
                const valuehe = e.target.value === 'true';
                setContactData(prevState => ({
                  ...prevState,
                  health_insurance: valuehe
                 
                }));
              }}
              className="form-field"
            >
              <MenuItem value="false">No</MenuItem>
              <MenuItem value="true">Yes</MenuItem>
            </Select>
          ) : ['social_insurance'].includes(field) ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={contactData[field] ? 'true' : 'false'}
              onChange={(e) => {
                const valueso = e.target.value === 'true';
                setContactData(prevState => ({
                  ...prevState,
                  social_insurance: valueso
                 
                }));
              }}
              className="form-field"
            >
              <MenuItem value="false">No</MenuItem>
              <MenuItem value="true">Yes</MenuItem>
            </Select>
          ) :  ['is_active_on_social_media'].includes(field) ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={contactData[field] ? 'true' : 'false'}
              onChange={(e) => {
                const valueac = e.target.value === 'true';
                setContactData(prevState => ({
                  ...prevState,
                  is_active_on_social_media: valueac
                 
                }));
              }}
              className="form-field"
            >
              <MenuItem value="false">No</MenuItem>
              <MenuItem value="true">Yes</MenuItem>
            </Select>
          ) :  ['welfare_fund'].includes(field) ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={contactData[field] || ''}
              onChange={(e) => handleChange(e, setContactData)}
              className="form-field"
              error={!!formErrors[field]}
            >
              {BOOLEAN_CHOICES.map((choice) => (
                <MenuItem key={choice} value={choice}>{choice}</MenuItem>
              ))}
            </Select>
          ) : field === 'caste' ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={contactData[field] || ''}
              onChange={(e) => handleChange(e, setContactData)}
              className="form-field"
              error={!!formErrors[field]}
            >
              {CASTE_CHOICES.map((choice) => (
                <MenuItem key={choice} value={choice}>{choice}</MenuItem>
              ))}
            </Select>
          ) :field === 'group' ? (
            <TextField
              name={field}
              fullWidth
              variant="outlined"
              value={selectedGroup}
              onChange={(e) => handleChange(e, setContactData)}
              className="form-field"
              error={!!formErrors[field]}
              helperText={formErrors[field]}
              InputProps={{
                readOnly: true,
              }}
              required
            />
          ) : field === 'gender' ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={contactData[field] || ''}
              onChange={(e) => handleChange(e, setContactData)}
              className="form-field"
              error={!!formErrors[field]}
              required={['name', 'gender', 'house_no', 'house_name'].includes(field)}
            >
              {GENDER_CHOICES.map((choice) => (
                <MenuItem key={choice} value={Array.isArray(choice) ? choice[0] : choice}>
                  {Array.isArray(choice) ? choice[1] : choice}
                </MenuItem>
              ))}
            </Select>
          ) : field === 'marital_status' ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={contactData[field] || ''}
              onChange={(e) => handleChange(e, setContactData)}
              className="form-field"
              error={!!formErrors[field]}
              required={['name', 'gender', 'house_no', 'house_name'].includes(field)}
            >
              {MARITAL_STATUS_CHOICES.map((choice) => (
                <MenuItem key={choice} value={choice}>{choice}</MenuItem>
              ))}
            </Select>
          // ) :field === 'religious_organization' ? (
          //   <Select
          //     name={field}
          //     fullWidth
          //     variant="outlined"
          //     value={contactData[field] || ''}
          //     onChange={(e) => handleChange(e, setContactData)}
          //     className="form-field"
          //     error={!!formErrors[field]}
          //     required={['name', 'gender', 'house_no', 'house_name'].includes(field)}
          //   >
          //     {RELIGION_ORGANIZATIONS_CHOICES.map((choice) => (
          //       <MenuItem key={choice} value={choice}>{choice}</MenuItem>
          //     ))}
          //   </Select>
          ) : ['pension'].includes(field) ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={contactData[field] ? 'true' : 'false'}
              onChange={(e) => {
                const values = e.target.value === 'true';
                setContactData(prevState => ({
                  ...prevState,
                  pension: values
                 
                }));
              }}
              className="form-field"
            >
              <MenuItem value="false">No</MenuItem>
              <MenuItem value="true">Yes</MenuItem>
            </Select>
          
        ):['eligible_to_vote'].includes(field) ? (
          <Select
            name={field}
            fullWidth
            variant="outlined"
            value={contactData[field] ? 'true' : 'false'}
            onChange={(e) => {
              const value = e.target.value === 'true';
              setContactData(prevState => ({
                ...prevState,
                eligible_to_vote: value
               
              }));
            }}
            className="form-field"
          >
            <MenuItem value="false">No</MenuItem>
            <MenuItem value="true">Yes</MenuItem>
          </Select>
        ): field === 'differently_abled' ? (
          <Select
            name={field}
            fullWidth
            variant="outlined"
            value={contactData[field] || ''}
            onChange={(e) => handleChange(e, setContactData)}
            className="form-field"
            error={!!formErrors[field]}
            required={['name', 'gender', 'house_no', 'house_name'].includes(field)}
          >
            {DIFFERENTLY_ABLED_CHOICES.map((choice) => (
              <MenuItem key={choice} value={choice}>{choice}</MenuItem>
            ))}
          </Select>
          ):field === 'education' ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={contactData[field] || ''}
              onChange={(e) => handleChange(e, setContactData)}
              className="form-field"
              error={!!formErrors[field]}
            >
              {EDUCATION_CHOICES.map((choice) => (
                <MenuItem key={choice} value={choice}>{choice}</MenuItem>
              ))}
            </Select>
        
        ) :  field === 'political_affiliation_status' ? (
          <Select
            name={field}
            fullWidth
            variant="outlined"
            value={contactData[field] || ''}
            onChange={(e) => handleChange(e, setContactData)}
            className="form-field"
            error={!!formErrors[field]}
          >
            {POLITICAL_STATUS_CHOICES.map((choice) => (
              <MenuItem key={choice} value={choice}>{choice}</MenuItem>
            ))}
          </Select>
      
      ) :field === 'occupation' ? (
        <Select
          name={field}
          fullWidth
          variant="outlined"
          value={contactData[field] || ''}
          onChange={(e) => handleChange(e, setContactData)}
          className="form-field"
          error={!!formErrors[field]}
        >
          {OCCUPATION_CHOICES.map((choice) => (
            <MenuItem key={choice} value={choice}>{choice}</MenuItem>
          ))}
        </Select>
      
      ) :field === 'field_of_education' ? (
        <Select
          name={field}
          fullWidth
          variant="outlined"
          value={contactData[field] || ''}
          onChange={(e) => handleChange(e, setContactData)}
          className="form-field"
          error={!!formErrors[field]}
        >
          {EDUCATION_FIELD_CHOICES.map((choice) => (
            <MenuItem key={choice} value={choice}>{choice}</MenuItem>
          ))}
        </Select>
        ) :field === 'profession' ? (
          <Select
            name={field}
            fullWidth
            variant="outlined"
            value={contactData[field] || ''}
            onChange={(e) => handleChange(e, setContactData)}
            className="form-field"
            error={!!formErrors[field]}
          >
            {PROFESSION_CHOICES.map((choice) => (
              <MenuItem key={choice} value={choice}>{choice}</MenuItem>
            ))}
          </Select>
        ) : field === 'vehicles' ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={contactData[field] || ''}
              onChange={(e) => handleChange(e, setContactData)}
              className="form-field"
              error={!!formErrors[field]}
            >
              {VEHICLE_CHOICES.map((choice) => (
                <MenuItem key={choice} value={choice}>{choice}</MenuItem>
              ))}
            </Select>
          ) : field === 'religion' ? (
            <Select
              name={field}
              fullWidth
              variant="outlined"
              value={contactData[field] || ''}
              onChange={(e) => handleChange(e, setContactData)}
              className="form-field"
              error={!!formErrors[field]}
            >
              {RELIGION_CHOICES.map((choice) => (
                <MenuItem key={choice} value={choice}>{choice}</MenuItem>
              ))}
            </Select>
          ) : (
            <TextField
              name={field}
              type={
                field === 'email' ? 'email' : 
                field === 'sl_no' || field === 'house_no' || field === 'aadhaar_number' ? 'number' : 
                'text'
              }
              inputProps={{ 
                pattern: "\\d*",
                readOnly: contactData.verified && isEditMode && (field === 'house_no' || field === 'house_name')
              }}
              fullWidth
              variant="outlined"
              value={contactData[field] || ''}
              onChange={(e) => handleChange(e, setContactData)}
              className="form-field"
              error={!!formErrors[field]}
              helperText={formErrors[field]}
              required={['group', 'name', 'gender', 'house_no', 'house_name'].includes(field)}
            />
          )}
           {/* {newContact.polling_booth_number  && (
  <Grid item xs={6}>
    <Typography variant="body1" className="field-label">
      polling booth number
    </Typography>
    <Select
      name="polling_booth_number"
      fullWidth
      variant="outlined"
      value={newContact.polling_booth_number}
      onChange={(e) => {
        handleChange(e, setNewContact);
        if (e.target.value === 'Add New') setNewPollNumber('');
      }}
      onOpen={() => fetchBooth()}
      className="form-field"
    >
      {boothChoices.polling_booth_number && boothChoices.polling_booth_number.map((choice) => (
        <MenuItem key={choice} value={choice}>
          {choice}
        </MenuItem>
      ))}
      <MenuItem value="Add New">Add New</MenuItem>
    </Select>
    {newContact.polling_booth_number === 'Add New' && (
      <TextField
        name="newPollNumber"
        fullWidth
        variant="outlined"
        placeholder="Enter new Booth Number"
        value={newPollNumber}
        onChange={(e) => {
          setNewPollNumber(e.target.value);
          handleChange({
            target: {
              name: 'polling_booth_number',
              value: 'Add New'
            }
          }, setNewContact);
        }}
        className="form-field"
        style={{ marginTop: '10px' }}
      />
    )}
  </Grid>
)}
 {newContact.polling_booth_name  && (
  <Grid item xs={6}>
    <Typography variant="body1" className="field-label">
      polling booth name
    </Typography>
    <Select
      name="polling_booth_name"
      fullWidth
      variant="outlined"
      value={newContact.polling_booth_name}
      onChange={(e) => {
        handleChange(e, setNewContact);
        if (e.target.value === 'Add New') setNewPollName('');
      }}
      onOpen={() => fetchBooth()}
      className="form-field"
    >
      {boothChoices.polling_booth_name && boothChoices.polling_booth_name.map((choice) => (
        <MenuItem key={choice} value={choice}>
          {choice}
        </MenuItem>
      ))}
      <MenuItem value="Add New">Add New</MenuItem>
    </Select>
    {newContact.polling_booth_name === 'Add New' && (
      <TextField
        name="newPollName"
        fullWidth
        variant="outlined"
        placeholder="Enter new Booth Name"
        value={newPollName}
        onChange={(e) => {
          setNewPollName(e.target.value);
          handleChange({
            target: {
              name: 'polling_booth_name',
              value: 'Add New'
            }
          }, setNewContact);
        }}
        className="form-field"
        style={{ marginTop: '10px' }}
      />
    )}
  </Grid>
)} */}
        </Grid>
        
      ));
    };


    const renderEditFormFields = (fields, contact, setContact) => {
      // Add null check
      if (!contact) return null;
      
      return fields.map((field) => {
        const isReadOnly = field === 'house_no' || field === 'house_name';
        
        return (
          <Grid item xs={12} sm={6} key={field}>
            <TextField
              fullWidth
              label={capitalizeFirstLetter(field.replace(/_/g, ' '))}
              name={field}
              value={contact[field] || ''}
              onChange={(e) => {
                if (!isReadOnly) {
                  setContact({ ...contact, [field]: e.target.value });
                }
              }}
              InputProps={{
                readOnly: isReadOnly,
              }}
            />
          </Grid>
        );
      });
    };
  
  return (
    <div>
     <Box sx={{ 
      borderColor: 'divider', 
      backgroundColor: '#E6E6FA', 
      borderRadius: '8px',
      marginBottom: '30px',
      overflowX: 'auto', 
      display: 'flex'
    }}>
      <Tabs 
        value={tabIndex} 
        onChange={handleTabChange} 
        aria-label="dashboard tabs" 
        variant="scrollable"
        TabIndicatorProps={{
          style: {
            backgroundColor: '#034694', 
          },
        }}
      >
         <Tab 
          icon={<HouseIcon />} 
          iconPosition="start" 
          label="HOUSEHOLD DATA" 
          sx={{
            color: tabIndex === 0 ? '#00ff00' : '',
            '&.Mui-selected': {
              color: '#034694',
            },
          }}
        />
    
        <Tab 
          icon={<PersonIcon />} 
          iconPosition="start" 
          label="INDIVIDUAL DATA" 
          sx={{
            color: tabIndex === 1 ? '#00ff00' : '',
            '&.Mui-selected': {
              color: '#034694',
            },
          }}
        />
       
        <Tab 
          icon={<GroupsIcon />} 
          iconPosition="start" 
          label="FAMILY DATA" 
          sx={{
            color: tabIndex === 2 ? '#00ff00' : '',
            '&.Mui-selected': {
              color: '#034694',
            },
          }}
        />
      </Tabs>
    </Box>



                {tabIndex === 1 && (
                    <div>
                
                <ButtonContainer>
        <AddButton onClick={openModal}>
          Add Individual &nbsp; <b>+</b>
        </AddButton>
      </ButtonContainer>
  
      <DataTable
  columns={columns}
  data={filteredData.length > 0 ? filteredData : [{
    sl_no: 'No data',
    name: 'No data ',
    guardian_name: 'No data ',
    gender: 'No data ',
    polling_booth_number: 'No data ',
    house_no: 'No data ',
    house_name: 'No data ',
    age: 'No data ',
    epic_no: 'No data ',
    remarks: 'No data',

  }]} 
  highlightOnHover
  responsive
  customStyles={customStyles}
/>
  


{/* <Dialog open={verificationModalOpen} onClose={() => setVerificationModalOpen(false)}>
  <DialogTitle>Verify Individual</DialogTitle>
  <DialogContent>
    <Grid container spacing={2}>
      {['house_no', 'house_name'].map((field) => (
        <Grid item xs={6} key={field}>
          <Typography variant="body1" className="field-label">
            {field.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase())}
            <span style={{ color: 'red' }}> *</span>
          </Typography>
          <TextField
            name={field}
            type="text"
            fullWidth
            variant="outlined"
            value={verificationData[field] || ''}
            onChange={(e) => setVerificationData({ ...verificationData, [field]: e.target.value })}
            className="form-field"
            required
          />
        </Grid>
      ))}
    </Grid>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setVerificationModalOpen(false)} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px', marginRight: '10px' }}>
                    Cancel
                  </Button>
    <Button onClick={handleVerification} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
                    Verify
                  </Button>
  </DialogActions>
</Dialog> */}
{/*  <Button onClick={() => setModalOpen(false)} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px', marginRight: '10px' }}>
                    Cancel
                  </Button>
                  <Button onClick={addRow} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
                    Submit
                  </Button> */}

            
<Dialog open={modalOpen} onClose={() => setModalOpen(false)} maxWidth="md"
  fullWidth >
        <DialogTitle>Add Individual Data - {capitalizeFirstLetter(selectedGroup)} </DialogTitle>
        <DialogContent>
          <Accordion expanded={expanded === 'basic'} onChange={handleAccordionChange('basic')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
              <b style={{ color: '#06368B' }}>Basic</b>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {renderFormFields(['name','house_no','polling_booth_number','polling_booth_name', 'house_name', 'sl_no','gender', 'epic_no'], newContact, setNewContact)}
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'personal'} onChange={handleAccordionChange('personal')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
              <b style={{ color: '#06368B' }}>Personal</b>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {renderFormFields([ 'dob', 'age', 'guardian_name', 'guardian_relation', 'aadhaar_number','differently_abled','vehicles','chronic_disease','marital_status','health_insurance','social_insurance','welfare_fund','pension','expatriate', 'eligible_to_vote'], newContact, setNewContact)}
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'education_job'} onChange={handleAccordionChange('education_job')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
              <b style={{ color: '#06368B' }}>Education Or Job</b>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {renderFormFields(['is_student','education','field_of_education','profession','occupation'], newContact, setNewContact)}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'social_political'} onChange={handleAccordionChange('social_political')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
              <b style={{ color: '#06368B' }}>Social and Political</b>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {renderFormFields(['religion', 'caste', 'religious_organizations', 'social_organizations', 'political_affiliation_party', 'political_affiliation_status'], newContact, setNewContact)}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'contact'} onChange={handleAccordionChange('contact')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
              <b style={{ color: '#06368B' }}>Contact</b>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {renderFormFields(['email','mobile_number','is_active_on_social_media','most_active_social_media','remarks'], newContact, setNewContact)}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Button onClick={() => setModalOpen(false)} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: isSmallScreen ? '150px' : '200px', marginRight: '10px', marginBottom: '10px', marginLeft: '460px' }}>
            Cancel
          </Button>
          <Button onClick={addRow} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: isSmallScreen ? '150px' : '200px', marginBottom: '10px' }}>
            Submit
          </Button>
        </Grid>
      </Dialog>
     

      <Dialog open={editModalOpen} onClose={() => setEditModalOpen(false)} maxWidth="md"
  fullWidth >
  <DialogTitle>Edit Individual Data - {capitalizeFirstLetter(selectedGroup)} </DialogTitle>
  <DialogContent>
  <Accordion expanded={expanded === 'basic'} onChange={handleAccordionChange('basic')}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
        <b style={{ color: '#06368B' }}>Basic</b>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {currentContact && renderFormFields(['name','house_no','polling_booth_number','polling_booth_name', 'house_name', 'sl_no','gender', 'epic_no'], currentContact, setCurrentContact, true)}
        </Grid>
      </AccordionDetails>
    </Accordion>

          <Accordion expanded={expanded === 'personal'} onChange={handleAccordionChange('personal')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
              <b style={{ color: '#06368B' }}>Personal</b>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {renderFormFields([ 'dob', 'age', 'guardian_name', 'guardian_relation', 'aadhaar_number','differently_abled','vehicles','chronic_disease','marital_status','health_insurance','social_insurance','welfare_fund','pension','expatriate', 'eligible_to_vote'], currentContact, setCurrentContact)}
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'education_job'} onChange={handleAccordionChange('education_job')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
              <b style={{ color: '#06368B' }}>Education Or Job</b>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {renderFormFields(['is_student','education','field_of_education','profession','occupation'], currentContact, setCurrentContact)}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'social_political'} onChange={handleAccordionChange('social_political')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
              <b style={{ color: '#06368B' }}>Social and Political</b>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {renderFormFields(['religion', 'caste', 'religious_organizations', 'social_organizations', 'political_affiliation_party', 'political_affiliation_status'], currentContact, setCurrentContact)}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'contact'} onChange={handleAccordionChange('contact')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
              <b style={{ color: '#06368B' }}>Contact</b>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {renderFormFields(['email','mobile_number','is_active_on_social_media','most_active_social_media','remarks'], currentContact, setCurrentContact)}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </DialogContent>
  <Grid item xs={12} sx={{ mt: 2 }}>
    <Button onClick={() => setEditModalOpen(false)} style={{  backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: isSmallScreen ? '150px' : '200px', marginRight: '10px', marginBottom: '10px', marginLeft: '460px' }}>
      Cancel
    </Button>
    <Button onClick={updateRow} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: isSmallScreen ? '150px' : '200px', marginBottom: '10px' }}>
      Update
    </Button>
  </Grid>
</Dialog>

                  </div>
                    
                  )}
                  {tabIndex === 0 && (
                    <div>
        <ButtonContainer>
        <AddButton  onClick={openHouseModal}> Add House &nbsp; <b>+</b> </AddButton>
      </ButtonContainer>
 <DataTable
  columns={houseColumns}
  data={filteredHouseData.length > 0 ? filteredHouseData : [{
    house_no: 'No data',
    house_name: 'No data ',
    total_house_members: 'No data ',
    total_house_voters: 'No data ',
    family_name: 'No data ',
    ration_card_type: 'No data ',
    remarks: 'No data',

  }]} 
  highlightOnHover
  responsive
  customStyles={customStyles}
/>

<Dialog 
  open={houseModalOpen} 
  onClose={() => setHouseModalOpen(false)}
  maxWidth="lg"
  fullWidth
>
  <DialogTitle>
    Add House Data - {capitalizeFirstLetter(selectedGroup)}
  </DialogTitle>
  <DialogContent>
    <Grid container spacing={2}>
      {Object.keys(newHouseData).map((field) => {
       if (['special_treatment', 'pets', 'agriculture_details', 'sub_caste'].includes(field)) {
          return null;
        }

        return (
          <React.Fragment key={field}>
            <Grid item xs={6}>
            <Typography variant="body1" className="field-label">
  {field === 'eligible_for_pension_but_not_receiving'
    ? 'Eligible for pension but not receiving'
    : field === 'no_name_on_ration_card'
    ? 'Anyone not added in ration card?(റേഷന്‍ കാര്‍ഡില്‍ പേരില്ലാത്തവര്‍?)'
    : field.replace(/_/g, ' ').toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}
  {['house_no', 'house_name', 'family_name'].includes(field) && (
    <span style={{ color: 'red' }}> *</span>
  )}
</Typography>
              {field === 'ration_card_type' ? (
                <Select
                  name={field}
                  fullWidth
                  variant="outlined"
                  value={newHouseData[field]}
                  onChange={(e) => handleChange(e, setNewHouseData)}
                  className="form-field"
                >
                  {RATION_CHOICES.map(([value, label]) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              ) :  ['toilet'].includes(field) ? (
                   <Select
                     name={field}
                     fullWidth
                     variant="outlined"
                     value={newHouseData[field] ? 'true' : 'false'}
                     onChange={(e) => {
                       const valueto = e.target.value === 'true';
                       setNewHouseData(prevState => ({
                         ...prevState,
                         toilet: valueto
                       
                       }));
                     }}
                     className="form-field"
                   >
                     <MenuItem value="false">No</MenuItem>
                     <MenuItem value="true">Yes</MenuItem>
                   </Select>
                
            ): ['electricity'].includes(field) ? (
              <Select
                name={field}
                fullWidth
                variant="outlined"
                value={newHouseData[field] ? 'true' : 'false'}
                onChange={(e) => {
                  const valueel = e.target.value === 'true';
                  setNewHouseData(prevState => ({
                    ...prevState,
                    electricity: valueel
                  
                  }));
                }}
                className="form-field"
              >
                <MenuItem value="false">No</MenuItem>
                <MenuItem value="true">Yes</MenuItem>
              </Select>
           
       ): ['land'].includes(field) ? (
        <Select
          name={field}
          fullWidth
          variant="outlined"
          value={newHouseData[field] ? 'true' : 'false'}
          onChange={(e) => {
            const valuela = e.target.value === 'true';
            setNewHouseData(prevState => ({
              ...prevState,
              land: valuela
            
            }));
          }}
          className="form-field"
        >
          <MenuItem value="false">No</MenuItem>
          <MenuItem value="true">Yes</MenuItem>
        </Select>
     
 ):  ['eligible_for_pension_but_not_receiving'].includes(field) ? (
  <Select
    name={field}
    fullWidth
    variant="outlined"
    value={newHouseData[field] ? 'true' : 'false'}
    onChange={(e) => {
      const valueep = e.target.value === 'true';
      setNewHouseData(prevState => ({
        ...prevState,
        eligible_for_pension_but_not_receiving: valueep
      
      }));
    }}
    className="form-field"
  >
    <MenuItem value="false">No</MenuItem>
    <MenuItem value="true">Yes</MenuItem>
  </Select>

):  ['no_name_on_ration_card'].includes(field) ? (
  <Select
    name={field}
    fullWidth
    variant="outlined"
    value={newHouseData[field] ? 'true' : 'false'}
    onChange={(e) => {
      const valuera = e.target.value === 'true';
      setNewHouseData(prevState => ({
        ...prevState,
        no_name_on_ration_card: valuera
      
      }));
    }}
    className="form-field"
  >
    <MenuItem value="false">No</MenuItem>
    <MenuItem value="true">Yes</MenuItem>
  </Select>

): ['road_access'].includes(field) ? (
                <Select
                  name={field}
                  fullWidth
                  variant="outlined"
                  value={newHouseData[field] ? 'true' : 'false'}
                  onChange={(e) => handleChange(e, setNewHouseData, true)}
                  className="form-field"
                >
                  <MenuItem value="false">No</MenuItem>
                  <MenuItem value="true">Yes</MenuItem>
                </Select>
              ) : field === 'waste_management' ? (
                <Select
                  name={field}
                  fullWidth
                  variant="outlined"
                  value={newHouseData[field]}
                  onChange={(e) => handleChange(e, setNewHouseData)}
                  className="form-field"
                >
                  {BOOLEAN_CHOICES.map((choice) => (
                    <MenuItem key={choice} value={choice}>
                      {choice}
                    </MenuItem>
                  ))}
                </Select>
              ) :  field === 'caste' ? (
                <Select
                  name={field}
                  fullWidth
                  variant="outlined"
                  value={newHouseData[field] || ''}
                  onChange={(e) => handleChange(e, setNewHouseData)}
                  className="form-field"
                >
                  {CASTE_CHOICES.map((choice) => (
                    <MenuItem key={choice} value={choice}>
                      {choice.charAt(0).toUpperCase() + choice.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              ) :   field === 'financial_status' ? (
                <Select
                  name={field}
                  fullWidth
                  variant="outlined"
                  value={newHouseData[field] || ''}
                  onChange={(e) => handleChange(e, setNewHouseData)}
                  className="form-field"
                >
                  {FINANCIAL_STATUS_CHOICES.map((choice) => (
                    <MenuItem key={choice} value={choice}>
                      {choice.charAt(0).toUpperCase() + choice.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              ) : field === 'religion' ? (
              <Select
                name={field}
                fullWidth
                variant="outlined"
                value={newHouseData[field] || ''}
                onChange={(e) => handleChange(e, setNewHouseData)}
                className="form-field"
              >
                {RELIGION_CHOICES.map((choice) => (
                  <MenuItem key={choice} value={choice}>{choice}</MenuItem>
                ))}
              </Select>
          ): field === 'drinking_water_source' ? (
                <Select
                  name={field}
                  fullWidth
                  variant="outlined"
                  value={newHouseData[field]}
                  onChange={(e) => handleChange(e, setNewHouseData)}
                  className="form-field"
                >
                  {WATER_SOURCE_CHOICES.map((choice) => (
                    <MenuItem key={choice} value={choice.toLowerCase()}>
                      {choice}
                    </MenuItem>
                  ))}
                </Select>
              ) : ['house_no', 'ration_card_number'].includes(field) ? (
                <TextField
                  name={field}
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={newHouseData[field]}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      handleChange(e, setNewHouseData);
                      setFormErrors((prevErrors) => ({
                        ...prevErrors,
                        [field]: ''
                      }));
                    } else {
                      setFormErrors((prevErrors) => ({
                        ...prevErrors,
                        [field]: `${field.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase())} must contain only digits.`
                      }));
                    }
                  }}
                  className="form-field"
                  error={!!formErrors[field]}
                  helperText={formErrors[field]}
                  required={['house_no', 'house_name', 'family_name'].includes(field)}
                  inputProps={{ pattern: "\\d*" }}
                />
              ) : field === 'family_name' ? (
                <TextField
                  name={field}
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={newHouseData[field]}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^[a-zA-Z\s]*$/.test(value)) {
                      handleChange(e, setNewHouseData);
                      setFormErrors((prevErrors) => ({
                        ...prevErrors,
                        [field]: ''
                      }));
                    } else {
                      setFormErrors((prevErrors) => ({
                        ...prevErrors,
                        [field]: `${field.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase())} must contain only alphabetic characters and spaces.`
                      }));
                    }
                  }}
                  className="form-field"
                  error={!!formErrors[field]}
                  helperText={formErrors[field]}
                  required={['house_no', 'house_name', 'family_name'].includes(field)}
                  inputProps={{ pattern: "[a-zA-Z\s]*" }}
                />
              ) : field === 'house_status' ? (
                <Select
                  name={field}
                  fullWidth
                  variant="outlined"
                  value={newHouseData[field]}
                  onChange={(e) => handleChange(e, setNewHouseData)}
                  className="form-field"
                >
                  {HOUSE_STATUS_CHOICES.map((choice) => (
                    <MenuItem key={choice} value={choice.toLowerCase()}>
                      {choice}
                    </MenuItem>
                  ))}
                </Select>
              ) : field === 'way_to_house' ? (
                <Select
                  name={field}
                  fullWidth
                  variant="outlined"
                  value={newHouseData[field]}
                  onChange={(e) => handleChange(e, setNewHouseData)}
                  className="form-field"
                >
                  {WAY_TO_HOUSE_CHOICES.map((choice) => (
                    <MenuItem key={choice} value={choice.toLowerCase()}>
                      {choice}
                    </MenuItem>
                  ))}
                </Select>
              ) : field === 'special_care' ? (
                <Select
                  name={field}
                  fullWidth
                  variant="outlined"
                  value={newHouseData[field]}
                  onChange={(e) => handleChange(e, setNewHouseData)}
                  className="form-field"
                >
                  {BOOLEAN_CHOICES.map((choice) => (
                    <MenuItem key={choice} value={choice}>
                      {choice}
                    </MenuItem>
                  ))}
                </Select>
              ) : field === 'pet' ? (
                <Select
                  name={field}
                  fullWidth
                  variant="outlined"
                  value={newHouseData[field]}
                  onChange={(e) => handleChange(e, setNewHouseData)}
                  className="form-field"
                >
                  {BOOLEAN_CHOICES.map((choice) => (
                    <MenuItem key={choice} value={choice}>
                      {choice}
                    </MenuItem>
                  ))}
                </Select>
              ) : field === 'agriculture' ? (
                <Select
                  name={field}
                  fullWidth
                  variant="outlined"
                  value={newHouseData[field]}
                  onChange={(e) => handleChange(e, setNewHouseData)}
                  className="form-field"
                >
                  {BOOLEAN_CHOICES.map((choice) => (
                    <MenuItem key={choice} value={choice}>
                      {choice}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <TextField
                  name={field}
                  fullWidth
                  variant="outlined"
                  value={newHouseData[field]}
                  onChange={(e) => handleChange(e, setNewHouseData)}
                  className="form-field"
                />
              )}
            </Grid>
            {field === 'caste' && newHouseData.caste && newHouseData.caste !== '' && (
  <Grid item xs={6}>
    <Typography variant="body1" className="field-label">
      Sub Caste
    </Typography>
    <Select
      name="sub_caste"
      fullWidth
      variant="outlined"
      value={newHouseData.sub_caste}
      onChange={(e) => {
        handleChange(e, setNewHouseData);
        if (e.target.value === 'Add New') setNewSubCaste('');
      }}
      onOpen={() => fetchChoices()}
      className="form-field"
    >
      {apiChoices.sub_castes && apiChoices.sub_castes.map((choice) => (
        <MenuItem key={choice} value={choice}>
          {choice}
        </MenuItem>
      ))}
      <MenuItem value="Add New">Add New</MenuItem>
    </Select>
    {newHouseData.sub_caste === 'Add New' && (
      <TextField
        name="newSubCaste"
        fullWidth
        variant="outlined"
        placeholder="Enter new sub caste"
        value={newSubCaste}
        onChange={(e) => {
          setNewSubCaste(e.target.value);
          handleChange({
            target: {
              name: 'sub_caste',
              value: 'Add New'
            }
          }, setNewHouseData);
        }}
        className="form-field"
        style={{ marginTop: '10px' }}
      />
    )}
  </Grid>
)}


            {field === 'special_care' && newHouseData.special_care === 'Yes' && (
          <Grid item xs={6}>
            <Typography variant="body1" className="field-label">
              Type of Care
            </Typography>
            <Select
              name="special_treatment"
              fullWidth
              variant="outlined"
              value={newHouseData.special_treatment}
              onChange={(e) => {
                handleChange(e, setNewHouseData);
                if (e.target.value === 'Add New') setNewSpecialTreatment('');
              }}
              onOpen={() => fetchChoices('specialTreatments')}
              className="form-field"
            >
              {apiChoices.specialTreatments && apiChoices.specialTreatments.map((choice) => (
                <MenuItem key={choice} value={choice}>
                  {choice}
                </MenuItem>
              ))}
              <MenuItem value="Add New">Add New</MenuItem>
            </Select>
            {newHouseData.special_treatment === 'Add New' && (
              <TextField
                name="newSpecialTreatment"
                fullWidth
                variant="outlined"
                placeholder="Enter new special treatment"
                value={newSpecialTreatment}
                onChange={(e) => setNewSpecialTreatment(e.target.value)}
                className="form-field"
                style={{ marginTop: '10px' }}
              />
            )}
          </Grid>
        )}
 
{field === 'pet' && newHouseData.pet === 'Yes' && (
          <Grid item xs={6}>
            <Typography variant="body1" className="field-label">
              Pets Types
            </Typography>
            <Select
              name="pets"
              fullWidth
              variant="outlined"
              value={newHouseData.pets}
              onChange={(e) => {
                handleChange(e, setNewHouseData);
                if (e.target.value === 'Add New') setNewPetsType('');
              }}
              onOpen={() => fetchChoices('petsTypes')}
              className="form-field"
            >
              {apiChoices.petsTypes && apiChoices.petsTypes.map((choice) => (
                <MenuItem key={choice} value={choice}>
                  {choice}
                </MenuItem>
              ))}
              <MenuItem value="Add New">Add New</MenuItem>
            </Select>
            {newHouseData.pets === 'Add New' && (
              <TextField
                name="newPetsType"
                fullWidth
                variant="outlined"
                placeholder="Enter new pets type"
                value={newPetsType}
                onChange={(e) => setNewPetsType(e.target.value)}
                className="form-field"
                style={{ marginTop: '10px' }}
              />
            )}
          </Grid>
        )}

{field === 'agriculture' && newHouseData.agriculture === 'Yes' && (
          <Grid item xs={6}>
            <Typography variant="body1" className="field-label">
              Agriculture Types
            </Typography>
            <Select
              name="agriculture_details"
              fullWidth
              variant="outlined"
              value={newHouseData.agriculture_details}
              onChange={(e) => {
                handleChange(e, setNewHouseData);
                if (e.target.value === 'Add New') setNewAgricultureType('');
              }}
              onOpen={() => fetchChoices('agricultureTypes')}
              className="form-field"
            >
              {apiChoices.agricultureTypes && apiChoices.agricultureTypes.map((choice) => (
                <MenuItem key={choice} value={choice}>
                  {choice}
                </MenuItem>
              ))}
              <MenuItem value="Add New">Add New</MenuItem>
            </Select>
            {newHouseData.agriculture_details === 'Add New' && (
              <TextField
                name="newAgricultureType"
                fullWidth
                variant="outlined"
                placeholder="Enter new agriculture type"
                value={newAgricultureType}
                onChange={(e) => setNewAgricultureType(e.target.value)}
                className="form-field"
                style={{ marginTop: '10px' }}
              />
            )}
                </Grid>
              )}
          </React.Fragment>
        );
      })}
    </Grid>
  </DialogContent>

  <DialogActions>
    <Button
      onClick={() => setHouseModalOpen(false)}
      style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}
    >
      Cancel
    </Button>
    <Button
      onClick={() => {
        if (newSpecialTreatment) {
          SPECIAL_TREATMENT_CHOICES.push(newSpecialTreatment);
        }
        if (newPetsType) {
          PETS_TYPES_CHOICES.push(newPetsType);
        }
        if (newAgricultureType) {
          AGRICULTURE_TYPES_CHOICES.push(newAgricultureType);
        }
        addHouseData();
      }}
      style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}
    >
      Submit
    </Button>

  </DialogActions>
</Dialog>


  {/* Edit House Data Dialog */}
  <Dialog open={editHouseModalOpen} onClose={() => setEditHouseModalOpen(false)} maxWidth="md"
  fullWidth>
      <DialogTitle>Edit House Data - {capitalizeFirstLetter(selectedGroup)} </DialogTitle>
      <DialogContent>
    <Grid container spacing={2}>
      {currentHouseData && Object.keys(currentHouseData)
        .filter(key => 
          key !== 'status' && 
          key !== 'added_by' && 
          key !== 'edited_by' && 
          key !== 'group' &&
          key !== 'house_no_variations' &&
          key !== 'house_name_variations' &&
          key !== 'total_house_national_expatriates' &&
          key !== 'total_house_international_expatriates' &&
          key !== 'family' &&
          key !== 'index' &&
          key !== 'head_of_household' &&
          key !== 'progress' &&
          key !== 'total_house_members' &&
          key !== 'total_house_voters' &&
          key !== 'id' &&
          key !== 'head_of_household_name'
        )
        .map((field) => {
         
          if (['special_treatment', 'pets', 'agriculture_details','sub_caste'].includes(field)) {
            return null;
          }

          return (
            <React.Fragment key={field}>
              <Grid item xs={6}>
              <Typography variant="body1" className="field-label">
  {field === 'eligible_for_pension_but_not_receiving'
    ? 'Eligible for pension but not receiving'
    : field === 'no_name_on_ration_card'
    ? 'Anyone not added in ration card?(റേഷന്‍ കാര്‍ഡില്‍ പേരില്ലാത്തവര്‍?)'
    : field.replace(/_/g, ' ').toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}
  {['house_no', 'house_name', 'family_name'].includes(field) && (
    <span style={{ color: 'red' }}> *</span>
  )}
</Typography>
          
                {field === 'ration_card_type' ? (
                  <Select
                    name={field}
                    fullWidth
                    variant="outlined"
                    value={currentHouseData[field]}
                    onChange={(e) => handleChange(e, setCurrentHouseData)}
                    className="form-field"
                  >
                    {RATION_CHOICES.map(([value, label]) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                ) :   ['toilet'].includes(field) ? (
                  <Select
                    name={field}
                    fullWidth
                    variant="outlined"
                    value={currentHouseData[field] ? 'true' : 'false'}
                    onChange={(e) => {
                      const valueto = e.target.value === 'true';
                      setCurrentHouseData(prevState => ({
                        ...prevState,
                        toilet: valueto
                      
                      }));
                    }}
                    className="form-field"
                  >
                    <MenuItem value="false">No</MenuItem>
                    <MenuItem value="true">Yes</MenuItem>
                  </Select>
               
           ): ['electricity'].includes(field) ? (
             <Select
               name={field}
               fullWidth
               variant="outlined"
               value={currentHouseData[field] ? 'true' : 'false'}
               onChange={(e) => {
                 const valueel = e.target.value === 'true';
                 setCurrentHouseData(prevState => ({
                   ...prevState,
                   electricity: valueel
                 
                 }));
               }}
               className="form-field"
             >
               <MenuItem value="false">No</MenuItem>
               <MenuItem value="true">Yes</MenuItem>
             </Select>
          
      ): ['land'].includes(field) ? (
       <Select
         name={field}
         fullWidth
         variant="outlined"
         value={currentHouseData[field] ? 'true' : 'false'}
         onChange={(e) => {
           const valuela = e.target.value === 'true';
           setCurrentHouseData(prevState => ({
             ...prevState,
             land: valuela
           
           }));
         }}
         className="form-field"
       >
         <MenuItem value="false">No</MenuItem>
         <MenuItem value="true">Yes</MenuItem>
       </Select>
    
):  ['eligible_for_pension_but_not_receiving'].includes(field) ? (
 <Select
   name={field}
   fullWidth
   variant="outlined"
   value={currentHouseData[field] ? 'true' : 'false'}
   onChange={(e) => {
     const valueep = e.target.value === 'true';
     setCurrentHouseData(prevState => ({
       ...prevState,
       eligible_for_pension_but_not_receiving: valueep
     
     }));
   }}
   className="form-field"
 >
   <MenuItem value="false">No</MenuItem>
   <MenuItem value="true">Yes</MenuItem>
 </Select>

):  ['no_name_on_ration_card'].includes(field) ? (
 <Select
   name={field}
   fullWidth
   variant="outlined"
   value={currentHouseData[field] ? 'true' : 'false'}
   onChange={(e) => {
     const valuera = e.target.value === 'true';
     setCurrentHouseData(prevState => ({
       ...prevState,
       no_name_on_ration_card: valuera
     
     }));
   }}
   className="form-field"
 >
   <MenuItem value="false">No</MenuItem>
   <MenuItem value="true">Yes</MenuItem>
 </Select>

): ['road_access'].includes(field) ? (
                  <Select
                    name={field}
                    fullWidth
                    variant="outlined"
                    value={currentHouseData[field] ? 'true' : 'false'}
                    onChange={(e) => handleChange(e, setCurrentHouseData, true)}
                    className="form-field"
                  >
                    <MenuItem value="false">No</MenuItem>
                    <MenuItem value="true">Yes</MenuItem>
                  </Select>
                ) : field === 'waste_management' ? (
                  <Select
                    name={field}
                    fullWidth
                    variant="outlined"
                    value={currentHouseData[field]}
                    onChange={(e) => handleChange(e, setCurrentHouseData)}
                    className="form-field"
                  >
                    {BOOLEAN_CHOICES.map((choice) => (
                      <MenuItem key={choice} value={choice}>
                        {choice}
                      </MenuItem>
                    ))}
                  </Select>
                ) :  field === 'caste' ? (
                  <Select
                    name={field}
                    fullWidth
                    variant="outlined"
                    value={currentHouseData[field] || ''}
                    onChange={(e) => handleChange(e, setCurrentHouseData)}
                    className="form-field"
                  >
                    {CASTE_CHOICES.map((choice) => (
                      <MenuItem key={choice} value={choice}>
                        {choice.charAt(0).toUpperCase() + choice.slice(1)}
                      </MenuItem>
                    ))}
                  </Select>
                  
                ) :field === 'financial_status' ? (
                  <Select
                    name={field}
                    fullWidth
                    variant="outlined"
                    value={currentHouseData[field] || ''}
                    onChange={(e) => handleChange(e, setCurrentHouseData)}
                    className="form-field"
                  >
                    {FINANCIAL_STATUS_CHOICES.map((choice) => (
                      <MenuItem key={choice} value={choice}>
                        {choice.charAt(0).toUpperCase() + choice.slice(1)}
                      </MenuItem>
                    ))}
                  </Select>
                  
                ) : field === 'religion' ? (
                <Select
                  name={field}
                  fullWidth
                  variant="outlined"
                  value={currentHouseData[field] || ''}
                  onChange={(e) => handleChange(e, setCurrentHouseData)}
                  className="form-field"
                >
                  {RELIGION_CHOICES.map((choice) => (
                    <MenuItem key={choice} value={choice}>{choice}</MenuItem>
                  ))}
                </Select>
            ): field === 'drinking_water_source' ? (
              <Select
              name={field}
              fullWidth
              variant="outlined"
              value={currentHouseData[field] || ''}
              onChange={(e) => handleChange(e, setCurrentHouseData)}
              className="form-field"
            >
              {WATER_SOURCE_CHOICES.map((choice) => (
                <MenuItem key={choice} value={choice}>{choice}</MenuItem>
              ))}
            </Select>
                ) : ['house_no', 'ration_card_number'].includes(field) ? (
                  <TextField
                    name={field}
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={currentHouseData[field]}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        handleChange(e, setCurrentHouseData);
                        setFormErrors((prevErrors) => ({
                          ...prevErrors,
                          [field]: ''
                        }));
                      } else {
                        setFormErrors((prevErrors) => ({
                          ...prevErrors,
                          [field]: `${field.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase())} must contain only digits.`
                        }));
                      }
                    }}
                    className="form-field"
                    error={!!formErrors[field]}
                    helperText={formErrors[field]}
                    required={['house_no', 'house_name', 'family_name'].includes(field)}
                    inputProps={{ pattern: "\\d*" }}
                  />
                ) : field === 'family_name' ? (
                  <TextField
                    name={field}
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={currentHouseData[field]}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^[a-zA-Z\s]*$/.test(value)) {
                        handleChange(e, setCurrentHouseData);
                        setFormErrors((prevErrors) => ({
                          ...prevErrors,
                          [field]: ''
                        }));
                      } else {
                        setFormErrors((prevErrors) => ({
                          ...prevErrors,
                          [field]: `${field.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase())} must contain only alphabetic characters and spaces.`
                        }));
                      }
                    }}
                    className="form-field"
                    error={!!formErrors[field]}
                    helperText={formErrors[field]}
                    required={['house_no', 'house_name', 'family_name'].includes(field)}
                    inputProps={{ pattern: "[a-zA-Z\s]*" }}
                  />
                ) : field === 'house_status' ? (
                  <Select
              name={field}
              fullWidth
              variant="outlined"
              value={currentHouseData[field] || ''}
              onChange={(e) => handleChange(e, setCurrentHouseData)}
              className="form-field"
            >
              {HOUSE_STATUS_CHOICES.map((choice) => (
                <MenuItem key={choice} value={choice}>{choice}</MenuItem>
              ))}
            </Select>
                ) : field === 'way_to_house' ? (
                  <Select
                    name={field}
                    fullWidth
                    variant="outlined"
                    value={currentHouseData[field]}
                    onChange={(e) => handleChange(e, setCurrentHouseData)}
                    className="form-field"
                  >
                    {WAY_TO_HOUSE_CHOICES.map((choice) => (
                      <MenuItem key={choice} value={choice.toLowerCase()}>
                        {choice}
                      </MenuItem>
                    ))}
                  </Select>
                ) : field === 'special_care' ? (
                  <Select
                    name={field}
                    fullWidth
                    variant="outlined"
                    value={currentHouseData[field]}
                    onChange={(e) => handleChange(e, setCurrentHouseData)}
                    className="form-field"
                  >
                    {BOOLEAN_CHOICES.map((choice) => (
                      <MenuItem key={choice} value={choice}>
                        {choice}
                      </MenuItem>
                    ))}
                  </Select>
                ) : field === 'pet' ? (
                  <Select
                    name={field}
                    fullWidth
                    variant="outlined"
                    value={currentHouseData[field]}
                    onChange={(e) => handleChange(e, setCurrentHouseData)}
                    className="form-field"
                  >
                    {BOOLEAN_CHOICES.map((choice) => (
                      <MenuItem key={choice} value={choice}>
                        {choice}
                      </MenuItem>
                    ))}
                  </Select>
                ) : field === 'agriculture' ? (
                  <Select
                    name={field}
                    fullWidth
                    variant="outlined"
                    value={currentHouseData[field]}
                    onChange={(e) => handleChange(e, setCurrentHouseData)}
                    className="form-field"
                  >
                    {BOOLEAN_CHOICES.map((choice) => (
                      <MenuItem key={choice} value={choice}>
                        {choice}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <TextField
                    name={field}
                    fullWidth
                    variant="outlined"
                    value={currentHouseData[field]}
                    onChange={(e) => handleChange(e, setCurrentHouseData)}
                    className="form-field"
                  />
                )}
              </Grid>
              {field === 'caste' && currentHouseData.caste && currentHouseData.caste !== '' && (
          <Grid item xs={6}>
          <Typography variant="body1" className="field-label">
          Sub Caste
          </Typography>
          <Select
          name="sub_caste"
          fullWidth
          variant="outlined"
          value={currentHouseData.sub_caste}
          onChange={(e) => {
          handleChange(e, setCurrentHouseData);
          if (e.target.value === 'Add New') setNewSubCaste('');
          }}
          onOpen={() => fetchChoices()}
          className="form-field"
          >
          {apiChoices.sub_castes && apiChoices.sub_castes.map((choice) => (
          <MenuItem key={choice} value={choice}>
            {choice}
          </MenuItem>
          ))}
          <MenuItem value="Add New">Add New</MenuItem>
          </Select>
          {currentHouseData.sub_caste === 'Add New' && (
          <TextField
          name="newSubCaste"
          fullWidth
          variant="outlined"
          placeholder="Enter new sub caste"
          value={newSubCaste}
          onChange={(e) => {
            setNewSubCaste(e.target.value);
            handleChange({
              target: {
                name: 'sub_caste',
                value: 'Add New'
              }
            }, setCurrentHouseData);
          }}
          className="form-field"
          style={{ marginTop: '10px' }}
          />
          )}
          </Grid>
          )}
          
          
              {field === 'special_care' && currentHouseData.special_care === 'Yes' && (
            <Grid item xs={6}>
              <Typography variant="body1" className="field-label">
                Type of Care
              </Typography>
              <Select
                name="special_treatment"
                fullWidth
                variant="outlined"
                value={currentHouseData.special_treatment}
                onChange={(e) => {
                  handleChange(e, setCurrentHouseData);
                  if (e.target.value === 'Add New') setNewSpecialTreatment('');
                }}
                onOpen={() => fetchChoices('specialTreatments')}
                className="form-field"
              >
                {apiChoices.specialTreatments && apiChoices.specialTreatments.map((choice) => (
                  <MenuItem key={choice} value={choice}>
                    {choice}
                  </MenuItem>
                ))}
                <MenuItem value="Add New">Add New</MenuItem>
              </Select>
              {currentHouseData.special_treatment === 'Add New' && (
                <TextField
                  name="newSpecialTreatment"
                  fullWidth
                  variant="outlined"
                  placeholder="Enter new special treatment"
                  value={newSpecialTreatment}
                  onChange={(e) => setNewSpecialTreatment(e.target.value)}
                  className="form-field"
                  style={{ marginTop: '10px' }}
                />
              )}
            </Grid>
          )}
          
          {field === 'pet' && currentHouseData.pet === 'Yes' && (
            <Grid item xs={6}>
              <Typography variant="body1" className="field-label">
                Pets Types
              </Typography>
              <Select
                name="pets"
                fullWidth
                variant="outlined"
                value={currentHouseData.pets}
                onChange={(e) => {
                  handleChange(e, setCurrentHouseData);
                  if (e.target.value === 'Add New') setNewPetsType('');
                }}
                onOpen={() => fetchChoices('petsTypes')}
                className="form-field"
              >
                {apiChoices.petsTypes && apiChoices.petsTypes.map((choice) => (
                  <MenuItem key={choice} value={choice}>
                    {choice}
                  </MenuItem>
                ))}
                <MenuItem value="Add New">Add New</MenuItem>
              </Select>
              {currentHouseData.pets === 'Add New' && (
                <TextField
                  name="newPetsType"
                  fullWidth
                  variant="outlined"
                  placeholder="Enter new pets type"
                  value={newPetsType}
                  onChange={(e) => setNewPetsType(e.target.value)}
                  className="form-field"
                  style={{ marginTop: '10px' }}
                />
              )}
            </Grid>
          )}
          
          {field === 'agriculture' && currentHouseData.agriculture === 'Yes' && (
            <Grid item xs={6}>
              <Typography variant="body1" className="field-label">
                Agriculture Types
              </Typography>
              <Select
                name="agriculture_details"
                fullWidth
                variant="outlined"
                value={currentHouseData.agriculture_details}
                onChange={(e) => {
                  handleChange(e, setCurrentHouseData);
                  if (e.target.value === 'Add New') setNewAgricultureType('');
                }}
                onOpen={() => fetchChoices('agricultureTypes')}
                className="form-field"
              >
                {apiChoices.agricultureTypes && apiChoices.agricultureTypes.map((choice) => (
                  <MenuItem key={choice} value={choice}>
                    {choice}
                  </MenuItem>
                ))}
                <MenuItem value="Add New">Add New</MenuItem>
              </Select>
              {currentHouseData.agriculture_details === 'Add New' && (
                <TextField
                  name="newAgricultureType"
                  fullWidth
                  variant="outlined"
                  placeholder="Enter new agriculture type"
                  value={newAgricultureType}
                  onChange={(e) => setNewAgricultureType(e.target.value)}
                  className="form-field"
                  style={{ marginTop: '10px' }}
                />
              )}
                  </Grid>
                )}
            </React.Fragment>
          );
          })}
          </Grid>
          </DialogContent>
          
          <DialogActions>
          <Button
          onClick={() => setEditHouseModalOpen(false)}
          style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}
          >
          Cancel
          </Button>
          <Button
          onClick={() => {
          if (newSpecialTreatment) {
            SPECIAL_TREATMENT_CHOICES.push(newSpecialTreatment);
          }
          if (newPetsType) {
            PETS_TYPES_CHOICES.push(newPetsType);
          }
          if (newAgricultureType) {
            AGRICULTURE_TYPES_CHOICES.push(newAgricultureType);
          }
          updateHouseData();
          }}
          style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}
          >
          Submit
          </Button>
          
          </DialogActions>
      {/* <DialogActions>
        <Button onClick={() => setEditHouseModalOpen(false)} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
          Cancel
        </Button>
        <Button onClick={updateHouseData} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
          Update
        </Button>
      </DialogActions> */}
    </Dialog>
      </div>
          )}
         {tabIndex === 2 && (
  <div>
    <ButtonContainer>
      <AddButton onClick={openFamilyModal}> Add Family &nbsp; <b>+</b> </AddButton>
    </ButtonContainer>


    <DataTable
  columns={familyColumns}
  data={filteredFamilyData.length > 0 ? filteredFamilyData : [{
    family_name: 'No data',
    total_family_households: 'No data ',
    total_family_members: 'No data ',
    total_family_voters: 'No data ',
    remarks: 'No data',
  }]} 
  highlightOnHover
  responsive
  customStyles={customStyles}
/>


    {/* Add Family Data Dialog */}
    <Dialog open={familyModalOpen} onClose={() => setFamilyModalOpen(false)} maxWidth="sm"
  fullWidth>
        <DialogTitle>Add Family Data - {capitalizeFirstLetter(selectedGroup)}</DialogTitle>
        <DialogContent>
        <Grid container spacing={2}>
          {Object.keys(newFamilyData).map((field) => (
            <Grid item xs={12} key={field}>
              <Typography variant="body1">
                {field.replace(/_/g, ' ').toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}
                {field === 'family_name' && <span style={{ color: 'red' }}> *</span>}
              </Typography>
              <TextField
                name={field}
                fullWidth
                variant="outlined"
                value={newFamilyData[field]}
                onChange={(e) => {
                  const value = e.target.value;
                  if (field === 'family_name') {
                    if (/^[a-zA-Z\s]*$/.test(value)) {
                      handleChange(e, setNewFamilyData);
                      setFormErrors(prevErrors => ({
                        ...prevErrors,
                        [field]: ''
                      }));
                    } else {
                      setFormErrors(prevErrors => ({
                        ...prevErrors,
                        [field]: 'Family Name must contain only alphabetic characters and spaces.'
                      }));
                    }
                  } else {
                    // For 'remarks', allow all characters
                    handleChange(e, setNewFamilyData);
                    setFormErrors(prevErrors => ({
                      ...prevErrors,
                      [field]: ''
                    }));
                  }
                }}
                className="form-field"
                error={!!formErrors[field]}
                helperText={formErrors[field]}
                required={field === 'family_name'}
                inputProps={field === 'family_name' ? { pattern: "[a-zA-Z\s]*" } : {}}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
        <DialogActions>
          <Button onClick={() => setFamilyModalOpen(false)} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
            Cancel
          </Button>
          <Button onClick={addFamilyData} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Family Data Dialog */}
      <Dialog open={editFamilyModalOpen} onClose={() => setEditFamilyModalOpen(false)} maxWidth="sm"
  fullWidth>
        <DialogTitle>Edit Family Data - {capitalizeFirstLetter(selectedGroup)}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
          {currentFamilyData && Object.keys(currentFamilyData)
            .filter(key =>
              key !== 'index' &&
              key !== 'id' &&
              key !== 'family_name_variations' &&
              key !== 'total_family_households' &&
              key !== 'total_family_members' &&
              key !== 'total_family_voters'&&
              key !== 'total_family_national_expatriates'&&
              key !== 'total_family_international_expatriates'&&
              key !== 'status'&&
              key !== 'added_by'&&
              key !== 'edited_by'&&
              key !== 'group' &&
              key !== 'progress'
              
            ).map((field) => (
              <Grid item xs={12} key={field}>
                <Typography variant="body1">
                  {field.replace(/_/g, ' ').toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}
                  {field === 'family_name' && <span style={{ color: 'red' }}> *</span>}
                </Typography>
                <TextField
                name={field}
                fullWidth
                variant="outlined"
                value={currentFamilyData[field]}
                onChange={(e) => {
                  const value = e.target.value;
                  if (field === 'family_name') {
                    if (/^[a-zA-Z\s]*$/.test(value)) {
                      handleChange(e, setCurrentFamilyData);
                      setFormErrors(prevErrors => ({
                        ...prevErrors,
                        [field]: ''
                      }));
                    } else {
                      setFormErrors(prevErrors => ({
                        ...prevErrors,
                        [field]: 'Family Name must contain only alphabetic characters and spaces.'
                      }));
                    }
                  } else {
                    // For 'remarks', allow all characters
                    handleChange(e, setCurrentFamilyData);
                    setFormErrors(prevErrors => ({
                      ...prevErrors,
                      [field]: ''
                    }));
                  }
                }}
                className="form-field"
                error={!!formErrors[field]}
                helperText={formErrors[field]}
                required={field === 'family_name'}
                inputProps={field === 'family_name' ? { pattern: "[a-zA-Z\s]*" } : {}}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditFamilyModalOpen(false)} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
            Cancel
          </Button>
          <Button onClick={updateFamilyData} style={{ backgroundColor: '#035391', color: '#fff', borderRadius: '45px', width: '200px' }}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
  </div>
)}
        </div>        
  );
}


const DeleteIcon = styled.span`
  cursor: pointer;
  margin-left: 10px;
`;
// const TableContainer = styled.div`
//   width: 100%;
//   overflow-x: auto;
//   background-color:#1877F21A;
//   border-radius:15px;
// ;
// `;

// const Table = styled.table`
//   width: 100%;
//   border-collapse: collapse;
//   min-width: 600px;
// `;

const ButtonContainer = styled.div`
  background-color: #6F89B7;
  padding: 13px;
  border-radius: 15px;
  display: flex;
  justify-content: flex-end;
   margin-bottom: 2px;
`;

// const TableCell = styled.td`
//   border: 4px solid #FFFFFF;
//   padding: 16px;
// `;

// const TableRow = styled.tr`
  
//     background-color: #1877F21A;


// `;

const EditIcon = styled.span`
  cursor: pointer;
`;


const AddButton = styled.button`
  padding: 10px 30px;
  background-color: #FFFFFF;
  color: #133E88;
  border: none;
  border-radius: 34px;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  &:hover {
    background-color: #cccccc;
  }
`;

const EmptyMessage = styled.div`
  text-align: center;
  padding: 20px;
  color: #888;
`;

const StyledInput = styled.input`
   width: ${props => props.width || '100%'};
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  background-color:#ccc;

  &:focus {
    outline: none;
    border-color: #1d1160;
    box-shadow: 0 0 5px rgba(29, 17, 96, 0.3);
  }

  &:hover {
    border-color: #1d1160;
  }
`;

const FilterRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 6px;
  padding: 10px 0;
  background-color: #ffffff; 
  width:600px;
  margin-left:8px;
  border-bottom: 2px solid #e1e1e1; 
`;


const ScrollableContainer = styled.div`
  overflow-x: auto;
  width: 100%; 
`;


// const FileButton = styled.button`
//   padding: 10px 30px;
//   background-color: #FFFFFF;
//   color: #133E88;
//   border: none;
//   border-radius: 34px;
//   font-weight: 700;
//   font-size: 18px;
//   cursor: pointer;
//   margin-right: 10px;
//   &:hover {
//     background-color: #cccccc;
//   }
// `;.


export default SurveysTab;