import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styled from "styled-components";
import { APIURL } from '../Global';
import './DetailsPage.css';


function FamilyDetailsPage() {
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const familyId = query.get('family_id');
  const token = query.get('token');

  const [familyDetails, setFamilyDetails] = useState(null);
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null);    
  const [selectedHouse, setSelectedHouse] = useState(null); 
  const [isModalOpen, setIsModalOpen] = useState(false);   


const DetailItem = ({ label, value }) => (
    <div className="detail-item">
      <span className="detail-label"><b>{label}:</b></span>
      <span className="detail-value">{value || 'N/A'}</span>
    </div>
  );
  const DetailItemPop = ({ label, value }) => (
    <div className="detail-item-pop">
      <span className="detail-label-pop"><b>{label}:</b></span>
      <span className="detail-value-pop">{value || 'N/A'}</span>
    </div>
  );

  const HeaderTitle = styled.h1`
  color: #2456ae;
  margin: 0;
  font-size: 18px;
`;
const HeaderLogo = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 50px;
    margin-right: 5px;
  }
`;
    const fetchFamilyDetails = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${APIURL}/data_collection/api/family/summary/?family_id=${familyId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          },
        });
        const data = await response.json();
        if (response.ok && data.status === 'success') {
          setFamilyDetails(data.data);
          setError(null);  // Clear any previous error..
        } else {
          setError('Failed to fetch family details.');
        }
      } catch (error) {
        setError('Error fetching family details.');
      } finally {
        setLoading(false);
      }
    };

  useEffect(() => {
      if (familyId && token) {
      fetchFamilyDetails();
    }
  }, [familyId, token]);

  const openModal = (household) => {
    setSelectedHouse(household);
    setIsModalOpen(true); 
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedHouse(null);
  };

  if (loading) {
    return <p style={{ color: '#073980' }}>Loading...</p>;
  }

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }
  // useEffect(() => {
  //   const fetchFamilyDetails = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await fetch(`${APIURL}/data_collection/api/family/summary/?family_id=${familyId}`, {
  //         method: 'GET',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'Authorization': `Token ${token}`,
  //         },
  //       });
  //       const data = await response.json();
  //       if (response.ok && data.status === 'success') {
  //         setFamilyDetails(data.data);
  //         setError(null);  // Clear any previous error
  //       } else {
  //         setError('Failed to fetch family details.');
  //       }
  //     } catch (error) {
  //       setError('Error fetching family details.');
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   if (familyId && token) {
  //     fetchFamilyDetails();
  //   }
  // }, [familyId, token]);

  if (loading) {
    return <p style={{ color: '#073980' }}>Loading...</p>; // Loader can be enhanced
  }

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  return (
    <>
    <div className="page-container">
   
    <nav className="left-navbar">
    <HeaderLogo>
            <img src="/images/company-logo.png" alt="Company Logo" />
            <HeaderTitle>CODEDC</HeaderTitle>
          </HeaderLogo>
    </nav>
    <div className="details-container">
    <h1 style={{ fontWeight: 'bold', color: '#041E42' ,fontSize:22}}>Family Details</h1>
    {familyDetails ? (
      <div className="details-grid">
        <DetailItem label="Family Name" value={familyDetails.family_name || 'N/A'} />
        <DetailItem label="Total Households" value={familyDetails.total_family_households || 'N/A'} />
        <DetailItem label="Total Members" value={familyDetails.total_family_members || 'N/A'} />
        <DetailItem label="Total Voters" value={familyDetails.total_family_voters || 'N/A'} />
        <DetailItem label="National Expatriates" value={familyDetails.total_family_national_expatriates || 'N/A'} />
        <DetailItem label="International Expatriates" value={familyDetails.total_family_international_expatriates || 'N/A'} />
        <DetailItem label="Remarks" value={familyDetails.remarks || 'N/A'} />
      </div>
    ) : (
      <p>No family details available.</p>
    )}
  
   
  <h2 style={{ color: '#073980', marginTop: '20px',fontSize:18 }}>Family Households</h2>
{familyDetails && familyDetails.households && familyDetails.households.length > 0 ? (
  <table className="another-table">
    <thead>
      <tr>
        <th>Household No</th>
        <th>Household Name</th>
        <th>Total Members</th>
        <th>Total Voters</th>
        <th>Family Name</th>
        <th>Ration Card Type</th>
      </tr>
    </thead>
    <tbody>
      {familyDetails.households.map(household => (
        <tr key={household.id}>
           <td>
          <button onClick={() => openModal(household)} className="clickable-button">
          {household.house_no}
                  </button>
                </td>
          <td>{household.house_name}</td>
          <td>{household.total_house_members}</td>
          <td>{household.total_house_voters}</td>
          <td>{household.family_name}</td>
          <td>{household.ration_card_type}</td>
        </tr>
      ))}
    </tbody>
  </table>
) : (
  <p>No households found.</p>
)}
 </div>


<Dialog open={isModalOpen} onClose={closeModal}   maxWidth="md"
>
  <DialogTitle style={{textAlign:'center',fontWeight:'bold',color:'#041E42',fontSize:16}}>{selectedHouse?.house_name} House Details</DialogTitle>
  <IconButton
      aria-label="close"
      onClick={closeModal}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
  <DialogContent>
  <Grid container spacing={2}>
  <Grid item xs={6}>
    <DetailItemPop label="House No" value={selectedHouse?.house_no} />
  </Grid>
  <Grid item xs={6}>
    <DetailItemPop label="House Name" value={selectedHouse?.house_name} />
  </Grid>
  <Grid item xs={6}>
    <DetailItemPop label="Family Name" value={selectedHouse?.family_name} />
  </Grid>
  <Grid item xs={6}>
    <DetailItemPop label="Head of Household" value={selectedHouse?.head_of_household_name || "N/A"} />
  </Grid>
  <Grid item xs={6}>
    <DetailItemPop label="Special Care" value={selectedHouse?.special_care} />
  </Grid>
  <Grid item xs={6}>
    <DetailItemPop label="Agriculture" value={selectedHouse?.agriculture} />
  </Grid>
  <Grid item xs={6}>
    <DetailItemPop label="Pets" value={selectedHouse?.pet} />
  </Grid>
  <Grid item xs={6}>
    <DetailItemPop label="Land" value={selectedHouse?.land ? "Yes" : "No"} />
  </Grid>
  <Grid item xs={6}>
    <DetailItemPop label="House Status" value={selectedHouse?.house_status} />
  </Grid>
  <Grid item xs={6}>
    <DetailItemPop label="Electricity" value={selectedHouse?.electricity ? "Yes" : "No"} />
  </Grid>
  <Grid item xs={6}>
    <DetailItemPop label="Toilet" value={selectedHouse?.toilet ? "Yes" : "No"} />
  </Grid>
  <Grid item xs={6}>
    <DetailItemPop label="Road Access" value={selectedHouse?.road_access} />
  </Grid>
  <Grid item xs={6}>
    <DetailItemPop label="Waste Management" value={selectedHouse?.waste_management} />
  </Grid>
  <Grid item xs={6}>
    <DetailItemPop label="Drinking Water Source" value={selectedHouse?.drinking_water_source} />
  </Grid>
  <Grid item xs={6}>
    <DetailItemPop label="Ration Card Type" value={selectedHouse?.ration_card_type} />
  </Grid>
  <Grid item xs={6}>
    <DetailItemPop label="Ration Card Number" value={selectedHouse?.ration_card_number || "N/A"} />
  </Grid>
  <Grid item xs={6}>
    <DetailItemPop label="No Name on Ration Card" value={selectedHouse?.no_name_on_ration_card ? "Yes" : "No"} />
  </Grid>
  <Grid item xs={6}>
    <DetailItemPop label="Eligible for Pension but not Receiving" value={selectedHouse?.eligible_for_pension_but_not_receiving ? "Yes" : "No"} />
  </Grid>
  <Grid item xs={6}>
    <DetailItemPop label="Special Treatment" value={selectedHouse?.special_treatment || "N/A"} />
  </Grid>
  <Grid item xs={6}>
    <DetailItemPop label="Agriculture Details" value={selectedHouse?.agriculture_details || "N/A"} />
  </Grid>
  <Grid item xs={6}>
    <DetailItemPop label="Pets" value={selectedHouse?.pets || "N/A"} />
  </Grid>
  <Grid item xs={6}>
    <DetailItemPop label="Financial Status" value={selectedHouse?.financial_status} />
  </Grid>
  <Grid item xs={6}>
    <DetailItemPop label="Religion" value={selectedHouse?.religion || "N/A"} />
  </Grid>
  <Grid item xs={6}>
    <DetailItemPop label="Caste" value={selectedHouse?.caste || "N/A"} />
  </Grid>
  <Grid item xs={6}>
    <DetailItemPop label="Sub Caste" value={selectedHouse?.sub_caste || "N/A"} />
  </Grid>
  <Grid item xs={6}>
    <DetailItemPop label="Religious Organizations" value={selectedHouse?.religious_organizations || "N/A"} />
  </Grid>
  <Grid item xs={6}>
    <DetailItemPop label="Remarks" value={selectedHouse?.remarks || "N/A"} />
  </Grid>
  
</Grid>

  </DialogContent>
  <DialogActions>
  </DialogActions>
</Dialog>


 
  </div>
  <Footer>
        <div style={{ fontWeight: 'bold' }}>CodeDC</div>
        <div>Copyright@2024 IntPurple Technologies LLP.</div>
        <div>Software Version</div>
      </Footer>
  </>
  );
}
const Footer = styled.footer`
  background-color: #1877F21A;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px;
  width: 100%;
  position: relative;
  bottom: 0;
  text-align: center;
  color: #073980;
  margin:auto;
`;


export default FamilyDetailsPage;
